import { Export } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useFormatSeconds } from '@/hooks'
import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    data: { orderSteps, totalServiceTime },
    close,
  } = useController()

  const formattedServiceTime = useFormatSeconds(totalServiceTime)

  return (
    <ModalHeader
      close={close}
      Icon={<Export size={16} />}
      subTitle={formattedServiceTime}
      title={texts.headerTitle(orderSteps.length)}
    />
  )
}
