import { H3, P2 } from '../../atoms'
import { Button } from '../Button'

import { ConfirmPanelContainer } from './elements/ConfirmPanelContainer'

import { Props } from './typings'

export const ConfirmPanel = (props: Props) => {
  const { confirmText, description, title, onConfirm, ...cosmeticProps } = props
  const { btnTint = '$darkOrange', btnAlpha = 0.4 } = props

  return (
    <ConfirmPanelContainer {...cosmeticProps}>
      <H3 style={{ marginBottom: 4 }}>{title}</H3>
      <P2 style={{ marginBottom: 12 }}>{description}</P2>
      <Button
        backgroundTint={btnTint}
        backgroundAlpha={btnAlpha}
        onClick={onConfirm}
        ellipsis
        autoWidth
        style={{
          minWidth: 90,
        }}
      >
        {confirmText}
      </Button>
    </ConfirmPanelContainer>
  )
}
