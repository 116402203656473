import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../hooks/useControllerActions'
import { useController } from '../../hooks/useController'

import { useTexts } from './useTexts'

export function Footer() {
  const texts = useTexts()
  const { close, data } = useController()
  const { onApplyFilters, onClearFilters } = useControllerActions()

  return (
    <ModalFooter>
      <ModalFooterButton
        onClick={onApplyFilters}
        color="primary"
        testid="modal__submit-primary-button"
        variant="contained"
      >
        {texts.apply}
      </ModalFooterButton>
      <ModalFooterButton
        onClick={onClearFilters}
        testid="modal__reset-button"
        disabled={data.filterPristine}
      >
        {texts.clear}
      </ModalFooterButton>

      <ModalFooterButton onClick={close} testid="modal__cancel-button">
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
