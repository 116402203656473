import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function InvalidFooter() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalFooter>
      <ModalFooterButton color="error" variant="contained" onClick={close}>
        {texts.dismiss}
      </ModalFooterButton>
    </ModalFooter>
  )
}
