import { Attention } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../hooks/useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      closeButtonClassName="closeButton"
      close={close}
      title={texts.headerTitle}
      Icon={<Attention size={16} />}
    />
  )
}
