import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useCanCopyToTerritory } from '../hooks/useCanCopyToTerritory'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const {
    status,
    invalid,
    close,
    data: { id },
  } = useController()

  const canCopyToTerritory = useCanCopyToTerritory(id)

  const disableButton = invalid || status !== 'ready' || !canCopyToTerritory

  return (
    <ModalFooter>
      <ModalFooterButton
        disabled={disableButton}
        onClick={actions.onConfirm}
        variant="contained"
        color="error"
      >
        {texts.replace}
      </ModalFooterButton>

      <ModalFooterButton onClick={close}>{texts.cancel}</ModalFooterButton>
    </ModalFooter>
  )
}
