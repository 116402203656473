import { format } from 'date-fns/esm'

const mock: typeof process.env.commitInfo = {
  committedOn: '0',
  subject: 'unknown',
  hash: 'unknown',
}

// quite strange but RollUp doesn't like the old
// const getCommitInfo = () => ((process.env.commitInfo as any) as CommitInfo) || mock
// version
const getCommitInfo = () => {
  return process.env.commitInfo || mock
}

const committedOn = (outputFormat: string = 'MMM do, h:mm aaa'): string => {
  const commitInfo = getCommitInfo()
  const { committedOn } = commitInfo

  return format(parseInt(committedOn) * 1000, outputFormat)
}

const commitUrl = (): string => {
  const commitInfo = getCommitInfo()
  const githubUrl: string = process.env.githubUrl as string
  const { hash } = commitInfo
  return `${githubUrl}/commit/${hash}`
}

const commitSubject = (): string => {
  return getCommitInfo().subject
}

export const getVersion = (): string => process.env.appVersion as string

let logged: boolean = false
export const logVersionInfo = (): void => {
  if (logged) return

  try {
    console.group()
    console.log('%c WorkWave RouteManager ', 'background: blue; color: white; display: block;')
    console.log(`%c Version: @${getVersion()} `, 'background: blue; color: white; display: block;')
    console.log(
      `%c Updated on: ${committedOn()} `,
      'background: blue; color: white; display: block;',
    )
    console.log(`%c Github: ${commitUrl()} `, 'background: blue; color: white; display: block;')
    console.log(
      `%c Commit subject: %c ${commitSubject()}`,
      'background: blue; color: white; display: block;',
      '',
    )
    console.log(`%c Built with Vite `, 'background: blue; color: white; display: block;')
    console.groupEnd()
  } catch (e) {
    console.warn('Failed to pring version')
    console.warn(e)
  }

  logged = true
}
