import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Logout(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 18">
        <path d="M8 1c.526 0 .875.349.875.875v7c0 .526-.349.875-.875.875s-.875-.349-.875-.875v-7C7.125 1.349 7.474 1 8 1zM4.309 2.094c.345-.069.704.037.902.3.263.438.157.968-.191 1.231C3.618 4.675 2.75 6.25 2.75 8c0 2.888 2.362 5.25 5.25 5.25s5.25-2.362 5.25-5.25c0-1.75-.868-3.33-2.27-4.293-.437-.263-.54-.793-.19-1.23.262-.438.792-.513 1.23-.164C13.858 3.625 15 5.806 15 8.082 15 11.845 11.849 15 8 15s-7-3.151-7-7c0-2.276 1.142-4.457 2.98-5.77.11-.064.212-.112.329-.136z" />
      </svg>
    </IconContainer>
  )
}
