import { CopyReasons } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      Icon={<CopyReasons size={16} />}
      subTitle={texts.modalSubTitle}
      title={texts.modalTitle}
      close={close}
    />
  )
}
