import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const { status, invalid, close } = useController()

  return (
    <ModalFooter>
      <ModalFooterButton
        disabled={invalid || status !== 'ready'}
        onClick={handlers.unassignOrders}
        variant="contained"
      >
        {texts.commandButtonTitle}
      </ModalFooterButton>

      <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
    </ModalFooter>
  )
}
