import { type SchedulerProConfig } from '@bryntum/schedulerpro'
import { getMainSelection } from '@/atoms'

// Handles the drag event
// Checks on multiple events and if the resource is locked
export const onBeforeEventDrag: SchedulerProConfig['onBeforeEventDrag'] = event => {
  const { assignmentRecords } = event

  // will block multi dnd
  const selectedOrderSteps = getMainSelection(true).orderSteps
  if (selectedOrderSteps.length !== 1) return false

  // just a check for ts
  if (assignmentRecords.length !== 1) return false
  const assignment = assignmentRecords[0]

  const resource = assignment.getResource()
  const locked = resource.getData('routeLock')

  // will block drag from a locked route
  if (locked) return false
}
