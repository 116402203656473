import { useCallback } from 'react'

import { WarningTriangle } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function InvalidHeader() {
  const texts = useTexts()
  const {
    status,
    close,
    data: { initiallySelectedRoutes },
  } = useController()

  const initiallySelectedRoutesCount = initiallySelectedRoutes.length

  const getTitle = useCallback(() => {
    switch (status) {
      case 'invalidForEmptyRoutes':
        return texts.invalidDataRoutesEmptyHeaderTitle(initiallySelectedRoutesCount)
      case 'invalidForSimulationEnvironment':
        return texts.title(initiallySelectedRoutesCount)
      default:
        return ''
    }
  }, [status, texts, initiallySelectedRoutesCount])

  return (
    <ModalHeader
      close={close}
      title={getTitle()}
      subTitle=""
      Icon={<WarningTriangle color="$pureWhite" background="$nightRider" size={18} />}
      hideCloseButton
      minHeight={74}
    />
  )
}
