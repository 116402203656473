import { ModalHeader } from '@/components/Modal'
import { Breadcrumbs } from '@/icons'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const { close } = useController()
  const texts = useTexts()

  return (
    <ModalHeader
      subTitle={texts.headerSubtitle}
      Icon={<Breadcrumbs size={17} />}
      title={texts.headerTitle}
      close={close}
    />
  )
}
