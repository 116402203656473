import { useState, useEffect, useCallback } from 'react'

export function useBanner(dispatchedDaysCount: number, maxSlotsToDispatch: number) {
  const [visible, setVisible] = useState(() => dispatchedDaysCount >= maxSlotsToDispatch)

  const forceClose = useCallback(() => {
    setVisible(false)
  }, [])

  useEffect(() => {
    setVisible(dispatchedDaysCount >= maxSlotsToDispatch)
  }, [dispatchedDaysCount, maxSlotsToDispatch])

  return [visible, forceClose] as const
}
