import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const {
    close,
    data: { bodyPlainText },
  } = useController()
  const actions = useControllerActions(bodyPlainText)

  const [copyText, onCopyText] = actions.copyText

  return (
    <ModalFooter>
      <ModalFooterButton
        className="pendo-orderform_order-notification-tab-view-settings-copy-text"
        testid="orderform_order-notification-tab-view-settings-copy-text"
        onClick={onCopyText}
        variant="contained"
      >
        {copyText}
      </ModalFooterButton>

      <ModalFooterButton
        onClick={close}
        className="pendo-orderform_order-notification-tab-view-settings-close-modal"
        testid="orderform_order-notification-tab-view-settings-close-modal"
      >
        {texts.close}
      </ModalFooterButton>
    </ModalFooter>
  )
}
