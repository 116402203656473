import { ModalHeader } from '@/components/Modal'
import { User } from '@/icons'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      title={texts.title}
      subTitle={texts.subtitle}
      close={close}
      Icon={<User size={22} />}
    />
  )
}
