import { ModalHeader } from '@/components/Modal'
import { Share } from '@/icons'

import { useController } from '../../../../hooks/useController'
import { useTexts } from '../../useTexts'

export function ComposeMessageHeader() {
  const texts = useTexts()
  const { close, data } = useController()

  return (
    <ModalHeader
      close={close}
      title={texts.shareLocation}
      Icon={<Share size={16} />}
      subTitle={data.gpsOnly ? texts.gpsOnlySubtitle : data.driverName}
    />
  )
}
