import { Recover } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader title={texts.configureTelematicsDemo} Icon={<Recover size={16} />} close={close} />
  )
}
