import { useMemo } from 'react'
import { parse } from 'date-fns/esm'
import { Export } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useFormatDateInterval } from '../../../../hooks'
import { useController } from '../hooks/useController'
import { useTexts } from '../hooks/useTexts'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { dates },
  } = useController()

  const startDate = useMemo(() => parse(dates[0], 'yyyyMMdd', new Date()), [dates])
  const endDate = useMemo(() => parse(dates[dates.length - 1], 'yyyyMMdd', new Date()), [dates])
  const value = useFormatDateInterval(startDate, endDate, false, true)

  return (
    <ModalHeader
      close={close}
      title={texts.title(dates.length, value)}
      subTitle=""
      Icon={<Export size={16} />}
      hideCloseButton
      minHeight={74}
    />
  )
}
