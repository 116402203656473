import { Star } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      title={texts.modalTitle}
      Icon={<Star size={18} />}
      subTitle={texts.modalSubtitle}
      close={close}
    />
  )
}
