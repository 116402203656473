import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelButtonTitle: translate({
      id: 'settings.integrations.editTenantSourceCredentials.cancelButtonTitle',
    }),
    saveButtonTitle: translate({
      id: 'settings.integrations.editTenantSourceCredentials.saveButtonTitle',
    }),
    headerTitle: (tenantSourceLabel: string) =>
      translate({
        id: 'settings.integrations.editTenantSourceCredentials.headerTitle',
        values: { tenantSourceLabel },
      }),
    successToast: translate({
      id: 'settings.integrations.editTenantSourceCredentials.successToast',
    }),
    azuga: {
      headerTitle: (tenantSourceLabel: string) =>
        translate({
          id: 'settings.integrations.editTenantSourceCredentials.azuga.headerTitle',
          values: { tenantSourceLabel },
        }),
      instructions: translate({
        id: 'settings.integrations.editTenantSourceCredentials.azuga.instructions',
      }),
      apiKeyLabel: translate({
        id: 'settings.integrations.editTenantSourceCredentials.azuga.apiKey',
      }),
      apiKeyHelperText: translate({
        id: 'settings.integrations.editTenantSourceCredentials.azuga.apiKey.helperText',
      }),
      errors: {
        apiKeyIncorrect: translate({
          id: 'settings.integrations.editTenantSourceCredentials.azuga.errors.apiKeyIncorrect',
        }),
        apiKeyRequired: translate({
          id: 'settings.integrations.editTenantSourceCredentials.azuga.errors.apiKeyRequired',
        }),
        apiKeyAlreadyUsed: translate({
          id: 'settings.integrations.editTenantSourceCredentials.azuga.errors.apiKeyAlreadyUsed',
        }),
      },
    },
    linxup: {
      headerTitle: (tenantSourceLabel: string) =>
        translate({
          id: 'settings.integrations.editTenantSourceCredentials.linxup.headerTitle',
          values: { tenantSourceLabel },
        }),
      instructions: translate({
        id: 'settings.integrations.editTenantSourceCredentials.linxup.instructions',
      }),
      apiKeyLabel: translate({
        id: 'settings.integrations.editTenantSourceCredentials.linxup.apiKey',
      }),
      apiKeyHelperText: translate({
        id: 'settings.integrations.editTenantSourceCredentials.linxup.apiKey.helperText',
      }),
      errors: {
        apiKeyIncorrect: translate({
          id: 'settings.integrations.editTenantSourceCredentials.linxup.errors.apiKeyIncorrect',
        }),
        apiKeyRequired: translate({
          id: 'settings.integrations.editTenantSourceCredentials.linxup.errors.apiKeyRequired',
        }),
        apiKeyAlreadyUsed: translate({
          id: 'settings.integrations.editTenantSourceCredentials.linxup.errors.apiKeyAlreadyUsed',
        }),
      },
    },
    verizonConnect: {
      headerTitle: (tenantSourceLabel: string) =>
        translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.headerTitle',
          values: { tenantSourceLabel },
        }),
      instructions: translate({
        id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.instructions',
      }),
      usernameLabel: translate({
        id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.username',
      }),
      passwordLabel: translate({
        id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.password',
      }),
      errors: {
        credentialsAlreadyInUse: translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.errors.credentialsAlreadyInUse',
        }),
        usernameIncorrect: translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.errors.usernameIncorrect',
        }),
        passwordIncorrect: translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.errors.passwordIncorrect',
        }),
        passwordRequired: translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.errors.passwordRequired',
        }),
        usernameRequired: translate({
          id: 'settings.integrations.editTenantSourceCredentials.verizonConnect.errors.usernameRequired',
        }),
      },
    },
  }))

  return api
}
