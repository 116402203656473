import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useResetNotificationEmailCounter } from '../hooks/useResetNotificationEmailCounter'
import { useController } from '../hooks/useController'
import { useTexts } from './useTexts'

export function Footer() {
  const { status, close } = useController()
  const resetNotificationEmailCounter = useResetNotificationEmailCounter()
  const texts = useTexts()

  const isLoading = status === 'submitting'

  return (
    <ModalFooter>
      <ModalFooterButton
        testid="settings-messaging-plan-reset-notification-email-counter-button"
        onClick={resetNotificationEmailCounter}
        disabled={isLoading}
        variant="contained"
        maxWidth="50%"
        color="error"
      >
        {texts.resetCounter}
      </ModalFooterButton>

      <ModalFooterButton
        testid="settings-messaging-plan-reset-notification-email-counter-cancel"
        onClick={close}
        variant="text"
      >
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
