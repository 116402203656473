import { Delete } from '@mui/icons-material'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { userIds },
  } = useController()

  return (
    <ModalHeader
      title={texts.title(userIds.length)}
      subTitle={texts.subtitle(userIds.length)}
      close={close}
      Icon={<Delete />}
    />
  )
}
