import { DoDisturb } from '@mui/icons-material'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function InvalidHeader() {
  const {
    close,
    data: { deletableDriverIds },
  } = useController()
  const texts = useTexts()

  const title =
    deletableDriverIds.length === 0
      ? texts.invalidSingleTitle
      : texts.title(deletableDriverIds.length)

  return <ModalHeader title={title} close={close} Icon={<DoDisturb />} />
}
