import { LicenseInfo } from '@mui/x-data-grid-pro'
import { useEffect } from 'react'

export function useInstanceLicenses() {
  useEffect(() => {
    // @ts-expect-error
    if (import.meta.env.VITE_MATERIAL_UI_X_LICENSE) {
      // @ts-expect-error
      LicenseInfo.setLicenseKey(import.meta.env.VITE_MATERIAL_UI_X_LICENSE)
    }
  }, [])
}
