import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'

import { useTexts } from '../hooks/useTexts'

interface Props {
  onSubmit: () => void
}

export function Footer(props: Props) {
  const { onSubmit } = props
  const { status, close } = useController()
  const texts = useTexts()

  const submitting = status === 'submitting'

  return (
    <ModalFooter>
      <ModalFooterButton
        testid="settings-rm-user-change-password-modal-change-button"
        disabled={submitting}
        maxWidth="50%"
        loading={submitting}
        variant="contained"
        onClick={onSubmit}
      >
        {texts.changePassword}
      </ModalFooterButton>

      <ModalFooterButton
        testid="pendo-settings-rm-user-change-password-modal-cancel-button"
        disabled={submitting}
        onClick={close}
        variant="text"
      >
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
