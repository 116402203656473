import type { PermissionData } from '../types'

export function computeDispatchPermission(permissionData: PermissionData) {
  if (permissionData.isSimulation || permissionData.allRoutesApproved) return 'hidden'

  if (!permissionData.enoughSlotsToDispatchRoutes) return 'disabledForNoDispatchSlots'

  if (permissionData.routesInThePastCount > 0) return 'disabledBecauseInThePast'

  return 'enabled'
}
