import type { Vehicle, RouteStep, DropDownItemStep } from '../typings'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'

import { selectRoutePlanTrackingData } from '@/features/domain/routeplan'
import { selectOrderSteps } from '@/features/domain/order'
import { conversionUtils } from '@/server-data'

import { useTexts } from '../useTexts'
import { isBestOption } from '../typings'
import { forceStartRefId } from '../constants'
import { useController } from './useController'
import {
  getStepId,
  isInProgress,
  hasBeenStarted,
  hasBeenExecuted,
  getDateFromDuration,
  composeBestItemWithLabel,
  forceStartPlaceholderRouteStep,
} from './utils'

export function useStepOptions(): DropDownItemStep[] {
  const texts = useTexts()
  const {
    data: { routes, userConfig, activeDate, activeStep, activeVehicle },
  } = useController()

  const routePlanTrackingData = useSelector(selectRoutePlanTrackingData)
  const allOrderSteps = useSelector(selectOrderSteps)

  const getExtraLabel = useCallback(
    (step: RouteStep, executed: boolean, inProgress: boolean) => {
      const convertTimeFromDate = conversionUtils.convertTimeFromDate(userConfig)

      if (step.type === 'departure' || step.type === 'arrival') return ''
      if (step.type === 'preparation' || step.type === 'closeOut') return ''
      if (executed) return convertTimeFromDate(getDateFromDuration(step.startSec), false)
      if (inProgress) return texts.inProgress
      if (step.type === 'delivery' || step.type === 'pickup')
        return `${texts.eta} ${convertTimeFromDate(getDateFromDuration(step.arrivalSec), false)}`

      return ''
    },
    [userConfig, texts],
  )

  return useMemo(() => {
    const date = activeDate as Date
    const vehicle = activeVehicle as Vehicle

    const activeStepId = isBestOption(activeStep) ? '' : getStepId(activeStep)

    // If we have a 'best' vehicle or date
    if (isBestOption(activeVehicle))
      return [
        isBestOption(activeDate)
          ? composeBestItemWithLabel(`best-best`, texts.setDateAndVehicleToProceed)
          : composeBestItemWithLabel(`best-${activeDate.getTime()}`, texts.setVehicleToProceed),
      ]

    if (isBestOption(activeDate))
      return [composeBestItemWithLabel(`${activeVehicle.id}-best`, texts.setDateToProceed)]

    const extendedRoute = routes[`${vehicle.id}-${format(activeDate, 'yyyyMMdd')}`]

    // If we don't have a route
    if (!extendedRoute)
      return [
        composeBestItemWithLabel(`${vehicle.id}-${date.getTime()}-empty`, texts.bestStep, false),
        {
          id: `${vehicle.id}-${date.getTime()}${forceStartRefId}`,
          kind: 'item',
          label: texts.start,
          routeStep: { ...forceStartPlaceholderRouteStep },
          selected: !isBestOption(activeStep) && activeStep?.ref === forceStartRefId,
        },
      ]

    // If we have a route
    const { route } = extendedRoute
    const hasRouteViolations = Object.keys(route.violations).length > 0

    const bestItem = composeBestItemWithLabel(
      `${vehicle.id}-${date.getTime()}`,
      texts.bestStep,
      false,
      hasRouteViolations,
    )

    const trackingData = routePlanTrackingData[route.id]
      ? routePlanTrackingData[route.id].routeTrackingData
      : undefined
    const preparationStarted =
      (trackingData?.preparationTrackingData?.timeInSec ?? -1) > -1 ?? false
    const closeOutStarted = (trackingData?.closeOutTrackingData?.timeInSec ?? -1) > -1 ?? false

    return route.steps.reduce(
      (acc: DropDownItemStep[], step: RouteStep, index: number, collection: RouteStep[]) => {
        if (step.type === 'departure') {
          acc.push({
            id: `${vehicle.id}-${date.getTime()}${forceStartRefId}`,
            kind: 'item',
            label: texts.start,
            routeStep: { ...forceStartPlaceholderRouteStep },
            selected: !isBestOption(activeStep) && activeStep?.ref === forceStartRefId,
          })

          return acc
        }

        const nextStep = collection[index + 1]

        if (step.type === 'preparation') return acc
        if (step.type === 'closeOut') return acc
        if (step.type === 'arrival') return acc

        const nextOrderStep = step.ref
          ? allOrderSteps[step.type === 'delivery' ? `${step.ref}-d` : `${step.ref}-p`]
          : null

        const nextStepStarted = nextStep
          ? hasBeenStarted(nextStep, route, nextOrderStep, preparationStarted, closeOutStarted)
          : false
        const executed = hasBeenExecuted(step, route)
        const inProgress = isInProgress(step, route)
        const id = getStepId(step)

        const extraLabel = getExtraLabel(step, executed, inProgress)
        const disabled = nextStepStarted
        const selected = activeStepId === id && !disabled

        let label = ''

        if (step.type === 'brk') label = texts.break

        if (step.ref) {
          const orderStepId = step.type === 'delivery' ? `${step.ref}-d` : `${step.ref}-p`
          const order = allOrderSteps[orderStepId]?.order

          if (order) {
            label = `${step.displayLabel} - ${order.name}`
          }
        }

        acc.push({
          id,
          selected,
          disabled,
          extraLabel,
          label,
          kind: 'item',
          routeStep: step,
        })

        return acc
      },
      [bestItem],
    )
  }, [
    routePlanTrackingData,
    allOrderSteps,
    activeVehicle,
    getExtraLabel,
    activeDate,
    activeStep,
    routes,
    texts,
  ])
}
