import { addSeconds, startOfToday, format as formatDate } from 'date-fns/esm'

import { separator, defaultValueAsString, halfDayInSecs } from './constants'

const timeFormats: Record<uui.domain.server.TimeFormat, string> = {
  // Apr 7 2022: product decide that AM/PM indication is no longer needed
  // in the component since there are already the AM/PM button
  H12: 'hh:mm',
  H24: 'HH:mm',
}

export function secToTimeHHmm(sec: number, openLabel: string = '', timeFormat: string): string {
  if (!Number.isFinite(sec)) {
    return ''
  }

  if (sec === -1) {
    // TODO: use localized text --> volo.i18n.getText('TW_OPEN_END_LABEL')
    return openLabel
  }

  const time: Date = addSeconds(startOfToday(), sec)

  return formatDate(time, timeFormat)
}

export const format = (value: number, h24: boolean): string => {
  const timeFormat = h24 ? timeFormats.H24 : timeFormats.H12
  return secToTimeHHmm(value, undefined, timeFormat)
}

export const parse = (valueAsString: string, isAm: boolean, h24: boolean): number => {
  const normalized: string = normalizeValueAsString(valueAsString)

  const [hh, mm] = normalized.split(separator)

  const hours = isNaN(parseInt(hh)) ? 0 : parseInt(hh)
  const minutes = isNaN(parseInt(mm)) ? 0 : parseInt(mm)

  const seconds = getSeconds(hours, minutes, isAm, h24)

  return Math.max(0, Math.min(seconds, halfDayInSecs * 2))
}

const getSeconds = (hours: number, minutes: number, isAm: boolean, h24: boolean): number => {
  if (h24) {
    return hours * 3600 + minutes * 60
  }

  return (hours % 12) * 3600 + minutes * 60 + (isAm ? 0 : halfDayInSecs)
}

export const normalizeValueAsString = (value: string): string => {
  const separatorIndex: number = value.indexOf(separator)
  const valueContainsSeparator: boolean = separatorIndex !== -1

  if (valueContainsSeparator) {
    return normalizeValueAsStringWithSeparator(value, value.length, separatorIndex, separator)
  }

  return normalizeValueAsStringWithNoSeparator(value, value.length, separator)
}

const normalizeValueAsStringWithNoSeparator = (
  value: string,
  length: number,
  separatorCharacter: string,
) => {
  switch (length) {
    case 1:
      return `0${value}${separatorCharacter}00`
    case 2:
      return `${value}${separatorCharacter}00`
    case 3: {
      const hours: string = value.slice(0, 2)
      const minutes: string = value.slice(2)
      return `${hours}${separatorCharacter}${minutes}0`
    }
    case 4: {
      const hours: string = value.slice(0, 2)
      const minutes: string = value.slice(2)
      return `${hours}${separatorCharacter}${minutes}`
    }
    case 5:
    default:
      return defaultValueAsString
  }
}

const normalizeValueAsStringWithSeparator = (
  value: string,
  length: number,
  separatorIndex: number,
  separatorCharacter: string,
): string => {
  switch (length) {
    case 1:
      return defaultValueAsString
    case 2:
      return separatorIndex === 0
        ? `00${separatorCharacter}${value}0`
        : `0${value}${separatorCharacter}00`
    case 3:
      switch (separatorIndex) {
        case 0:
          return `00${value}`
        case 1:
          return `0${value}0`
        case 2:
        default:
          return `${value}00`
      }
    case 4:
      switch (separatorIndex) {
        case 0:
        case 1:
          return `0${value}`
        case 2:
        case 3:
          return `${value}0`
      }
    case 5:
    default:
      return value
  }
}
