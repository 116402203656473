import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Rename(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <path d="M.125.125h15.75v15.75H.125z" fill="none" fillOpacity="0" />
          <path
            fillRule="nonzero"
            d="M2.094 2.094a.984.984 0 00-.985.984v9.844h6.976l.984-.985H2.094v-6.89h10.828v3.144A1.56 1.56 0 0113.658 8c.084 0 .167.011.248.024V3.078a.983.983 0 00-.984-.984H2.094zm1.968 4.43a.492.492 0 100 .987.492.492 0 000-.988zm1.97 0a.493.493 0 00-.001.984h4.43a.492.492 0 100-.985h-4.43zm-1.97 1.968a.492.492 0 100 .985.492.492 0 000-.985zm1.97 0a.493.493 0 00-.001.985h4.43a.492.492 0 100-.985h-4.43zm7.626.492a.588.588 0 00-.417.173l-.417.418 1.476 1.476.417-.417a.59.59 0 000-.835l-.64-.642a.595.595 0 00-.419-.173zm-1.425 1.182l-2.362 2.362.442.148.148.738.738.148.148.442 2.362-2.362-1.476-1.476zm1.673 2.671l-.084.085h.084v-.085zm-4.581.918l-.328.93-.001.002a.145.145 0 00.032.161.145.145 0 00.156.034h.001l.936-.33-.796-.797z"
          />
        </g>
      </svg>
    </IconContainer>
  )
}
