import { Ticket } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const { close } = useController()
  const texts = useTexts()

  return (
    <ModalHeader
      close={close}
      title={texts.title}
      subTitle={texts.description}
      Icon={<Ticket size={16} />}
    />
  )
}
