import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SendCommunication(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 14">
        <path d="M1.42661 0.826238C1.25691 0.662205 1.37303 0.375 1.60905 0.375H13.8478C14.0838 0.375 14.2 0.662205 14.0303 0.826238L8.37443 6.29334C8.01272 6.64434 7.44469 6.64434 7.08244 6.29334L1.42661 0.826238ZM0.125 1.72552C0.125 1.4938 0.403332 1.37573 0.569939 1.53678L4.23028 5.07499C4.33448 5.17571 4.33734 5.34181 4.23669 5.44606L0.576343 9.23713C0.412357 9.40697 0.125 9.29089 0.125 9.0548V1.72552ZM15.3319 1.7255C15.3319 1.49378 15.0536 1.37572 14.887 1.53676L11.3742 4.93213C11.6029 4.89838 11.8354 4.875 12.0733 4.875C13.3268 4.875 14.4667 5.36757 15.3319 6.17139V1.7255ZM8.27156 9.9375C8.27156 7.76288 9.97365 6 12.0733 6C14.1729 6 15.875 7.76288 15.875 9.9375C15.875 12.1121 14.1729 13.875 12.0733 13.875C9.97365 13.875 8.27156 12.1121 8.27156 9.9375ZM5.40121 6.20764C5.29691 6.10693 5.13071 6.10985 5.03001 6.21417L1.32234 10.0552C1.16147 10.2218 1.27957 10.5 1.51121 10.5H6.92462C7.08081 10.5 7.20171 10.3644 7.19302 10.2085C7.18802 10.1188 7.18534 10.0285 7.18534 9.9375C7.18534 9.27141 7.31248 8.63755 7.53928 8.05573C7.60906 7.8767 7.48252 7.67184 7.29495 7.63013C6.94785 7.55294 6.61682 7.38176 6.34098 7.11511L5.40121 6.20764ZM12.5246 8.15496C12.3606 7.98512 12.0733 8.1012 12.0733 8.33729V9.375H10.444C10.1442 9.375 9.90087 9.62644 9.90087 9.9375C9.90087 10.2486 10.1442 10.5 10.444 10.5H12.0733V11.5377C12.0733 11.7738 12.3606 11.8899 12.5246 11.72L14.2457 9.9375L12.5246 8.15496Z" />
      </svg>
    </IconContainer>
  )
}
