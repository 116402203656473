import { useCallback, useState } from 'react'
import { Grid } from '@mui/material'

import { intl } from '@/intl'
import { useEditTenantSourceCredentialsModal } from '../../../modals/EditTenantSourceCredentialsModal'
import { useEditTenantSourceLabelModal } from '../../../modals/EditTenantSourceLabelModal'
import { useDeleteTenantSourceModal } from '../../../modals/DeleteTenantSourceModal'
import { getSourceDetails } from '../../../utils/getSourceDetails'

import { ActiveIntegrationCard } from './ActiveIntegrationCard'

type Props = {
  activeIntegrations: uui.domain.server.gps.telematics.TenantSource[]
  setIntegrationId: (integrationId: string | undefined) => void
  userRole: 'admin' | 'courier' | 'planner' | 'viewer' | 'guest' | 'gpsonly'
}

export function ActiveIntegrationsGrid(props: Props) {
  const { userRole, activeIntegrations, setIntegrationId } = props
  const { show: showDeleteTenantSourceModal, Modal: DeleteTenantSourceModal } =
    useDeleteTenantSourceModal()

  const { show: showEditTenantSourceLabelModal, Modal: EditTenantSourceLabelModal } =
    useEditTenantSourceLabelModal()

  const { show: showEditTenantSourceCredentialsModal, Modal: EditTenantSourceCredentialsModal } =
    useEditTenantSourceCredentialsModal()

  const [tenantSource, setTenantSource] = useState<
    uui.domain.server.gps.telematics.TenantSource | undefined
  >()

  const deleteIntegration = useCallback(
    (tenantSource: uui.domain.server.gps.telematics.TenantSource) => {
      setTenantSource(tenantSource)
      showDeleteTenantSourceModal()
    },
    [showDeleteTenantSourceModal],
  )

  const changeLabel = useCallback(
    (tenantSource: uui.domain.server.gps.telematics.TenantSource) => {
      setTenantSource(tenantSource)
      showEditTenantSourceLabelModal()
    },
    [showEditTenantSourceLabelModal],
  )

  const changeCredentials = useCallback(
    (tenantSource: uui.domain.server.gps.telematics.TenantSource) => {
      setTenantSource(tenantSource)
      showEditTenantSourceCredentialsModal()
    },
    [showEditTenantSourceCredentialsModal],
  )

  return (
    <Grid container spacing={1} paddingY={4} width="100%">
      {activeIntegrations.map(integration => {
        const { logoBigUrl, descriptionId } = getSourceDetails(integration.sourceLabel)
        return (
          <Grid item md={6} lg={4} key={integration.id}>
            <ActiveIntegrationCard
              logo={logoBigUrl}
              userRole={userRole}
              integration={integration}
              description={intl.translate({ id: descriptionId })}
              changeLabel={changeLabel}
              setIntegrationId={setIntegrationId}
              changeCredentials={changeCredentials}
              deleteIntegration={deleteIntegration}
            />
          </Grid>
        )
      })}
      {userRole === 'admin' && (
        <div style={{ position: 'absolute' }}>
          {tenantSource && <DeleteTenantSourceModal tenantSource={tenantSource} />}
          {tenantSource && <EditTenantSourceLabelModal tenantSource={tenantSource} />}
          {tenantSource && <EditTenantSourceCredentialsModal tenantSource={tenantSource} />}
        </div>
      )}
    </Grid>
  )
}
