import { Delete } from '@mui/icons-material'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function RegularHeader() {
  const texts = useTexts()
  const {
    close,
    data: { driverIds },
  } = useController()

  return <ModalHeader title={texts.title(driverIds.length)} close={close} Icon={<Delete />} />
}
