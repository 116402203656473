import type { ReactChild, ReactFragment } from 'react'

import { Box, Typography } from '@mui/material'

interface Props {
  label: string
  children: ReactChild | ReactFragment
  color?: string
  testId?: string
}

export function ViewField(props: Props) {
  const { label, children, color = 'textPrimary', testId = 'rm-viewField-root' } = props

  return (
    <Box paddingBottom={4} data-testid={testId}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>

      <Typography variant="body1" color={color}>
        {children}
      </Typography>
    </Box>
  )
}
