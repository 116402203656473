import { useMemo } from 'react'

import { intl } from '@/intl'
import { Paired } from '@/icons'
import { durationUtils } from '@/server-data'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const texts = useTexts()
  const { close, data } = useController()

  const formatSeconds = useMemo(() => durationUtils.formatSeconds(intl.translate), [])

  const title = data.issue === 'none' ? texts.headerTitle(data.orderStep.type) : ''

  const subTitle =
    data.issue === 'none'
      ? texts.headerSubTitle(
          data.pairedOrderStep.type,
          data.order.name,
          formatSeconds(data.pairedOrderStep.serviceTimeSec, 'LONG'),
        )
      : ''

  return (
    <ModalHeader
      closeButtonClassName="pendo-moving-paired-order-step_close-modal"
      close={close}
      title={title}
      Icon={<Paired size={16} />}
      subTitle={subTitle}
    />
  )
}
