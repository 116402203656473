import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isAfter, parseISO } from 'date-fns/esm'

import { selectApprovedPlans, selectMaxSlotsForDispatch } from '@/features/domain/routeplan'
import { selectUserConfiguration } from '@/features/domain/user'
import { dateUtils } from '@/utils'

export function useGotEnoughSlotsToDispatch(todayAsString: string) {
  const maxSlotsToDispatch = selectMaxSlotsForDispatch()
  const approvedPlans = useSelector(selectApprovedPlans)
  const { startOfToday } = useSelector(selectUserConfiguration)

  const todayDate = parseISO(startOfToday)

  const approvedFutureDates = useMemo(
    () =>
      new Set<string>(
        Object.keys(approvedPlans).filter((dateAsString: string) => {
          const date = dateUtils.parseDate(dateAsString)
          return isAfter(date, todayDate)
        }),
      ),
    [approvedPlans, todayDate],
  )

  return useCallback(
    (routeDates: string[]) => {
      const newDatesToApprove = Array.from(routeDates).reduce<Set<string>>((acc, date) => {
        if (date === todayAsString || approvedFutureDates.has(date)) {
          return acc
        }

        acc.add(date)
        return acc
      }, new Set<string>())

      return newDatesToApprove.size <= maxSlotsToDispatch - 1 - approvedFutureDates.size
    },
    [todayAsString, approvedFutureDates, maxSlotsToDispatch],
  )
}
