import type { CSSProperties } from 'react'
import type { ValidColor } from '@/local/components'

import styled from 'styled-components'

import { theme } from '@/local/components'

type Props = {
  size?: number
  className?: string
  title?: string
  color?: ValidColor
  style?: CSSProperties
  as?: 'span' | 'div'
  animation?: 'spin'
  marginLeft?: number
  marginRight?: number
}

const IconContainer = styled.span<Props>`
  display: ${p => (p.as === 'div' ? 'block' : 'inline-block')};
  width: ${p => p.size ?? `${p.size}px` ?? '100%'};
  margin-left: ${p => p.marginLeft ?? 0};
  margin-right: ${p => p.marginRight ?? 0};

  animation: none;
`

export function TickIcon(props: Props) {
  1
  const { color } = props

  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 8 8">
        <path
          style={{ fill: color ? theme.colors[color] : 'currentcolor' }}
          d="M6.5 1l1.08 1.126-4.714 4.518L.5 4.376 1.58 3.25l1.286 1.232z"
        />
      </svg>
    </IconContainer>
  )
}
