import { useMemo } from 'react'

import { ModalFooter } from '@/components/Modal'

import { useController } from '../../hooks/useController'

import { ChooseRecipientFooter } from './components/ChooseRecipientFooter'
import { ComposeMessageFooter } from './components/ComposeMessageFooter'
import { SuccessFooter } from './components/SuccessFooter'
import { FailureFooter } from './components/FailureFooter'

export function Footer() {
  const { status } = useController()

  const footerButtons = useMemo(() => {
    switch (status) {
      case 'composeMessage':
        return <ComposeMessageFooter />

      case 'chooseRecipient':
        return <ChooseRecipientFooter />

      case 'success':
        return <SuccessFooter />

      case 'failure':
        return <FailureFooter />
    }
  }, [status])

  return <ModalFooter>{footerButtons}</ModalFooter>
}
