import type { TimerState } from '../../hooks/useTransactionTimer'

import { CircularProgress } from '@mui/material'
import { Recover, Undo, Redo, Breadcrumbs, Check } from '@/icons'
import { useIsLoading } from '@/atoms'

import { Container, Separator } from '@/components/primitives/ControlStrip'
import { Button, IconButton } from '@/components/primitives/buttons'
import { HasPrivileges } from '@/components/HasPrivileges'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../useTexts'
import { useTransactionControls } from './hooks/useTransactionControls'

type Props = {
  onResetTimer: () => void
  transactionState: TimerState
}

export function TransactionControls(props: Props) {
  const { onResetTimer, transactionState } = props

  const texts = useTexts()
  const {
    handleOnApply,
    handleOnRedo,
    handleOnResetTimer,
    handleOnRollback,
    handleOnUndo,
    disableRollback,
    disableRedo,
    disableUndo,
    disableSave,
  } = useTransactionControls(transactionState, onResetTimer)

  const isGotoPrevLoading = useIsLoading('goToPreviousRevision')
  const isGotoNextLoading = useIsLoading('goToNextRevision')

  const paused = transactionState === 'paused'

  return (
    <Container>
      <HasPrivileges dev>
        <Tooltip
          placement="top"
          title={texts.rollback}
          subtitle={disableRollback ? texts.rollbackDisabled : undefined}
        >
          <IconButton
            disabled={disableRollback}
            onClick={handleOnRollback}
            data-testid="rollback-button"
          >
            <Recover size={14} color="$pureWhite" />
          </IconButton>
        </Tooltip>
      </HasPrivileges>

      <Tooltip
        placement="top"
        title={texts.resetTimer}
        subtitle={paused ? texts.resetTimerDisabled : undefined}
      >
        <IconButton
          disabled={disableRollback}
          onClick={handleOnResetTimer}
          data-testid="reset-timer-button"
        >
          <Breadcrumbs size={14} color="$pureWhite" />
        </IconButton>
      </Tooltip>

      <Separator />

      <Tooltip
        placement="top"
        title={texts.undo}
        subtitle={disableUndo ? texts.undoDisabled : undefined}
      >
        {isGotoPrevLoading ? (
          <CircularProgress size={16} color="inherit" sx={{ marginLeft: 2, marginRight: 2 }} />
        ) : (
          <IconButton disabled={disableUndo} onClick={handleOnUndo} data-testid="undo-button">
            <Undo size={14} color="$pureWhite" />
          </IconButton>
        )}
      </Tooltip>

      <Tooltip
        placement="top"
        title={texts.redo}
        subtitle={disableRedo ? texts.redoDisabled : undefined}
      >
        {isGotoNextLoading ? (
          <CircularProgress size={16} color="inherit" sx={{ marginLeft: 2, marginRight: 2 }} />
        ) : (
          <IconButton disabled={disableRedo} onClick={handleOnRedo} data-testid="redo-button">
            <Redo size={14} color="$pureWhite" />
          </IconButton>
        )}
      </Tooltip>

      <Separator />

      <Tooltip
        placement="top"
        title={texts.saveChanges}
        disabled={disableSave}
        subtitle={disableSave ? texts.saveChangesDisabled : undefined}
      >
        <Button
          autoWidth={true}
          paddingLeft={16}
          paddingRight={16}
          color="$pureWhite"
          preset="footerButton"
          disabled={disableSave}
          onClick={handleOnApply}
          data-testid="commit-button"
          Icon={<Check size={14} color="$pureWhite" />}
        >
          {texts.saveChanges}
        </Button>
      </Tooltip>
    </Container>
  )
}
