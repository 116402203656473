import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../../hooks/useController'
import { useOnSubmit } from '../../hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

interface Props {
  setFirstSubmitDone: (value: boolean) => void
}

export function Footer(props: Props) {
  const { setFirstSubmitDone } = props

  const { status, close } = useController()
  const onSubmit = useOnSubmit(setFirstSubmitDone)
  const texts = useTexts()

  const submitting = status === 'submitting'

  return (
    <ModalFooter>
      <ModalFooterButton
        testid="driver-change-password-modal-change-button"
        disabled={submitting}
        loading={submitting}
        variant="contained"
        onClick={onSubmit}
        maxWidth="50%"
      >
        {texts.changePassword}
      </ModalFooterButton>

      <ModalFooterButton
        testid="driver-change-password-modal-cancel-button"
        disabled={submitting}
        onClick={close}
        variant="text"
      >
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
