import { Export } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { selectedRoutes },
  } = useController()

  return (
    <ModalHeader
      close={close}
      title={texts.title(selectedRoutes.length)}
      subTitle=""
      Icon={<Export size={16} />}
      hideCloseButton
      minHeight={74}
    />
  )
}
