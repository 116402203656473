import { ModalHeader } from '@/components/Modal'
import { Share } from '@/icons'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function SendMessageHeader() {
  const texts = useTexts()
  const { close, data } = useController()

  return data.gpsOnly ? (
    <ModalHeader
      close={close}
      title={texts.gpsOnlyTitle}
      Icon={<Share size={16} />}
      subTitle={texts.gpsOnlySubtitle}
    />
  ) : (
    <ModalHeader
      close={close}
      title={texts.title}
      Icon={<Share size={16} />}
      subTitle={data.driverName}
    />
  )
}
