const typeConversionTable = {
  accountManagement: 'A_ACCOUNT_MANAGEMENT',
  admin: 'ADMIN',
  betaAccess: 'BETA_ACCESS',
  demo: 'DEMO',
  dev: 'DEV',
  export: 'EXPORT',
  exportToEmail: 'EXPORT_TO_EMAIL',
  free: 'FREE',
  grantPrivileges: 'A_GRANT_PRIVILEGES',
  loginAs: 'A_LOGIN_AS', // represent that user could start a session in "login as"
} as const

type Privilege = keyof typeof typeConversionTable

type ExtendedPrivilege = Privilege | 'loginAsPasspartout'
export type ExtendedPrivilegesMap = Partial<Record<ExtendedPrivilege, boolean>>

const convertPrivilege = (privilege: Privilege): uui.domain.server.rm.UserPrivilege => {
  const convertedValue = typeConversionTable?.[privilege]

  if (!convertedValue && process.env.NODE_ENV === 'development') {
    throw new Error(`Unknown privilege ${privilege}`)
  }

  return convertedValue
}

function includesPrivilege(
  privileges: uui.domain.server.rm.UserPrivilege[],
  privilegeToCheck: ExtendedPrivilege,
  isLoginAs: boolean, // user is in a "login as" session
) {
  if (privilegeToCheck === 'loginAsPasspartout') {
    return isLoginAs
  }
  return privileges.includes(convertPrivilege(privilegeToCheck))
}

export const checkPrivileges = (
  userPrivileges: uui.domain.server.rm.UserPrivilege[],
  requiredPrivileges: ExtendedPrivilegesMap,
  exact: boolean,
  isLoginAs: boolean, // user is in a "login as" session
) => {
  const required = Object.entries(requiredPrivileges)
    .filter(([_privilege, isRequired]: [ExtendedPrivilege, boolean]) => isRequired)
    .map(([privilege]: [ExtendedPrivilege, boolean]) => privilege)

  return exact
    ? required.every(privilege => includesPrivilege(userPrivileges, privilege, isLoginAs))
    : required.some(privilege => includesPrivilege(userPrivileges, privilege, isLoginAs))
}
