import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    subtitle: (maxSlotsToDispatch: number, dispatchedDaysCount: number, limitReached: boolean) =>
      limitReached
        ? translate({
            id: 'dispatchPanel.maxDaysDispatched',
            values: { count: maxSlotsToDispatch },
          })
        : translate({
            id: 'dispatchPanel.header.subtitle',
            values: { count: dispatchedDaysCount, total: maxSlotsToDispatch },
          }),
    headerTitle: translate({ id: 'dispatchPanel.header.title' }),
    bannerTitle: (count: number) =>
      translate({
        id: 'dispatchPanel.banner.title',
        values: { count },
      }),
    bannerDescription: translate({ id: 'dispatchPanel.banner.description' }),
    bannerConfirmText: translate({ id: 'dispatchPanel.banner.confirmText' }),
  }))

  return api
}
