import { Delete } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    data: { name },
    close,
  } = useController()

  return (
    <ModalHeader
      title={texts.modalTitle(name)}
      Icon={<Delete size={16} />}
      subTitle={texts.modalSubTitle}
      close={close}
    />
  )
}
