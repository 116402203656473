import { Typography } from '@mui/material'

import { HasPrivileges } from '@/components/HasPrivileges'

import { useDevicesInfo } from './hooks/useDevicesInfo'

export function VirtualDevices() {
  const devicesInfo = useDevicesInfo()

  return (
    <HasPrivileges dev loginAsPasspartout>
      <div style={{ width: '100%', padding: '0 0.5rem' }}>
        <Typography variant="body1" style={{ margin: '0.5rem 0', textDecoration: 'underline' }}>
          Virtual Device assignment list
        </Typography>
        <ul style={{ margin: 0 }}>
          {devicesInfo.map(info => (
            <li style={{ margin: '4px 0' }} key={info.id}>
              <Typography variant="body2">{formatDeviceInfo(info)}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </HasPrivileges>
  )
}

function formatDeviceInfo(info: ReturnType<typeof useDevicesInfo>[number]) {
  const driverName = info.driverName ? `(${info.driverName})` : ''
  const account = info.accountUsername ? `| ${info.accountUsername}` : ''
  const territory = info.territoryName ? `[${info.territoryName} ${account}]` : ''

  return `${info.id} ${driverName} ${territory}`
}
