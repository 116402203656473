import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { useActions } from '../hooks/useActions'

import { useTexts } from '../useTexts'

export function Footer() {
  const { selectImportedOrders } = useActions()
  const texts = useTexts()

  return (
    <ModalFooter>
      <ModalFooterButton variant="contained" onClick={selectImportedOrders}>
        {texts.ok}
      </ModalFooterButton>
    </ModalFooter>
  )
}
