import type { FormFields, FormErrors, Priority as PriorityType } from '../../../formFields'

import { BulkSelect } from '../../primitives/BulkSelect'
import { useTexts } from './useTexts'
import { useOptions } from './useOptions'

export function Priority() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <BulkSelect<'priority', PriorityType, FormFields, FormErrors>
      name="priority"
      mixedLabel={texts.mixed}
      options={options}
      label={texts.priority}
      testId="priority"
    />
  )
}
