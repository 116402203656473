import { FileCopy } from '@mui/icons-material'
import { styled } from '@mui/material'

import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

const FileCopyIcon = styled(FileCopy, { name: 'FileCopyIcon' })({
  fontSize: 15,
})

export function Header() {
  const {
    close,
    data: { orderStepIds },
  } = useController()

  const texts = useTexts()

  return (
    <ModalHeader
      title={texts.title(orderStepIds.length, 'partial')}
      Icon={<FileCopyIcon />}
      subTitle={texts.subtitle}
      close={close}
    />
  )
}
