import { ModalHeader } from '@/components/Modal'
import { Update } from '@/icons'

import { useController } from '../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      Icon={<Update size={16} />}
      subTitle={texts.subtitle}
      title={texts.title}
      close={close}
    />
  )
}
