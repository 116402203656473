import { useMemo } from 'react'

import { intl } from '@/intl'
import { getRecurrenceLabel } from '@/server-data'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: {
      trafficProfile: {
        recurrence: { type, value },
      },
    },
  } = useController()

  const { translate } = intl

  const recurrence = useMemo(
    () => getRecurrenceLabel(type, value, translate),
    [type, value, translate],
  )

  return <ModalHeader close={close} title={texts.header.title(recurrence)} />
}
