import { Stack } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../useTexts'
import { Footer } from './Footer'

export function ConfigureColumnsFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    close,
    status,
    invalid,
    data: { selectedOrders },
  } = useController()

  const bulk = selectedOrders.length > 1

  return (
    <Footer>
      <ModalFooterButton onClick={handlers.onPrevButtonClick} testid="export-order-prev-button">
        <KeyboardArrowLeft />
        {texts.prevStepButton}
      </ModalFooterButton>
      <Stack gap={1} direction="row">
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={handlers.exportOrder}
          variant="contained"
          loading={handlers.isLoading}
          testid="export-order-export-button"
        >
          {bulk
            ? texts.exportButtonTitleBulk(selectedOrders.length)
            : texts.exportButtonTitleSingle}
        </ModalFooterButton>
        <ModalFooterButton onClick={close} testid="export-order-cancel-button">
          {texts.cancelButtonTitle}
        </ModalFooterButton>
      </Stack>
    </Footer>
  )
}
