import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Payload = {
  dates: string[]
  config: {
    format: 'XLSX' | 'CSV' | 'HTML' | 'EMAIL'
    includePod?: boolean
    includeBarcodes?: boolean
    timeFormat?: string
    currentRoutes?: boolean
  }
}

/**
 * Export routes in different formats
 *
 * @private
 */
export const exportRoutesByDates = createAsyncThunk<
  // Return type of the payload creator
  string | uui.domain.actions.rpc.route.EmailExportResult | null,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/route/export/byDates', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/route/exportByDates', {
      category: 'rpc',
      type: 'rpc/route/exportByDates',
      payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    // Download the attachment
    if (payload.config.format !== 'EMAIL' && typeof response.result === 'string') {
      window.open(response.result)
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {exportRoutes}`,
      { tags: ['rpc', 'order'], info: { payload, error } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Export routes [${payload.dates.join(', ')}] failed`,
      error,
    })
  }
})
