import { useCallback } from 'react'

import { changeTerritory } from '@/features/domain/territory'
import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { useAppDispatch } from '@/store'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const dispatch = useAppDispatch()
  const {
    close,
    status,
    data: { newTerritoryId },
    invalid,
  } = useController()

  const handleChangeTerritory = useCallback(() => {
    if (!newTerritoryId) return

    close?.()
    dispatch(changeTerritory({ target: 'ops', territoryId: newTerritoryId }))
  }, [dispatch, close, newTerritoryId])

  return (
    <ModalFooter>
      <ModalFooterButton
        disabled={invalid || status !== 'ready'}
        onClick={handleChangeTerritory}
        variant="contained"
      >
        {texts.footerBtnOpenText}
      </ModalFooterButton>
      <ModalFooterButton onClick={close}>{texts.footerBtnCancelText}</ModalFooterButton>
    </ModalFooter>
  )
}
