import type { Option } from '../../primitives/BulkSelect'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectSortedRMVehicles } from '@/features/domain/vehicle'

import { useTexts } from './useTexts'

export function useOptions() {
  const vehicles = useSelector(selectSortedRMVehicles)
  const texts = useTexts()

  return useMemo(() => {
    const vehicleOptions = vehicles.map<Option<string>>(vehicle => ({
      label: vehicle.externalId,
      value: vehicle.id,
    }))

    const noneOption = {
      label: texts.none,
      value: '',
    }

    return [noneOption, ...vehicleOptions]
  }, [vehicles, texts])
}
