import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Badge, Typography } from '@mui/material'
import { CellTower } from '@mui/icons-material'
import { Tooltip } from '@/components/primitives/Tooltip'
import {
  DropdownMenu,
  MenuIconTrigger,
  DropdownMenuItem,
  DropdownTextItem,
  NestedDropdown,
} from '@/components/DropdownMenu'
import { useGotIntegrationErrors } from '@/hooks'
import { useRequestLogModal } from '@/components/DemoTools/components/RequestLogModal'
import { useChangelogModal } from '@/components/modals/ChangelogModal'
import { VerticalLayout } from '@/components/layout'
import { HasPrivileges } from '@/components/HasPrivileges'
import { Text } from '@/local/components'
import { User } from '@/icons'

import { useInitializeSettingsMenu } from './hooks/useInitializeSettingsMenu'
import { useTexts } from './useTexts'

export function SettingsDropdownMenu() {
  const {
    open,
    email,
    setOpen,
    gpsOnly,
    fullName,
    canLogout,
    editingType,
    LogoutModal,
    goToSettings,
    openLogoutModal,
  } = useInitializeSettingsMenu()

  const { show: showRequestLog, Modal: RequestLog, close: closeRequestLog } = useRequestLogModal()
  const { show: showChangelog, Modal: Changelog } = useChangelogModal()
  const gotIntegrationsErrors = useGotIntegrationErrors()
  const location = useLocation()
  const texts = useTexts()

  const active = location.pathname.startsWith('/settings')

  const settingsSlotProps = {
    badge: {
      style: {
        transform: 'translate(15px, -1px)',
        display: gotIntegrationsErrors ? 'block' : 'none',
      },
    },
  }

  const handleChangelog = useCallback(() => {
    showChangelog()
    setOpen(false)
  }, [setOpen, showChangelog])

  const handleRequestLog = useCallback(() => {
    showRequestLog()
    setOpen(false)
  }, [setOpen, showRequestLog])

  return (
    <>
      <DropdownMenu
        open={open}
        setOpen={setOpen}
        trigger={
          <Tooltip placement="bottom" title={texts.settingsArea}>
            <Badge color="error" variant="dot" invisible={!gotIntegrationsErrors}>
              <MenuIconTrigger
                active={active}
                menuOpen={open}
                Icon={<User size={16} color="$pureWhite" />}
                testid="header__settings-area-trigger"
                className="pendo-bluebar__settings-area-menu"
              />
            </Badge>
          </Tooltip>
        }
      >
        <div data-testid="header__settings-area-container">
          <DropdownTextItem>
            <VerticalLayout>
              <Text
                ellipsis
                size="$m"
                weight="$semiBold"
                data-testid="header__settings-area-user-fullname"
              >
                {fullName}
              </Text>
              <Text
                ellipsis
                size="$s"
                weight="$light"
                data-testid="header__settings-area-user-email"
              >
                {email}
              </Text>
            </VerticalLayout>
          </DropdownTextItem>

          <DropdownMenuItem
            external
            onClick={goToSettings}
            testid="header__settings-area-settings-button"
            className="pendo-bluebar__settings-area-settings-button"
          >
            <Badge color="error" variant="dot" slotProps={settingsSlotProps}>
              {texts.settings}
            </Badge>
          </DropdownMenuItem>

          <HasPrivileges dev>
            <NestedDropdown
              placement="left"
              parentOpen={open}
              triggerContent={
                <Typography fontWeight={500} fontSize={14} color="black">
                  {texts.debugTools}
                </Typography>
              }
            >
              <DropdownMenuItem
                onClick={handleRequestLog}
                className="pendo-bluebar__request-log"
                testid="request-log-button"
                Icon={<CellTower fontSize="small" sx={{ transform: 'translateY(-2px)' }} />}
              >
                {texts.requestMaker}
              </DropdownMenuItem>

              <DropdownMenuItem onClick={handleChangelog}>{texts.changelog}</DropdownMenuItem>
            </NestedDropdown>
          </HasPrivileges>

          <Tooltip
            placement="bottom"
            disabled={canLogout}
            title={texts.editInProgressTitle(gpsOnly, editingType)}
            subtitle={texts.editInProgressSubtitle(gpsOnly)}
          >
            <DropdownMenuItem
              warn
              disabled={!canLogout}
              onClick={openLogoutModal}
              testid="header__settings-area-logout-button"
              className="pendo-bluebar__settings-area-logout-button"
            >
              {texts.logout}
            </DropdownMenuItem>
          </Tooltip>
        </div>
      </DropdownMenu>

      <LogoutModal />
      <RequestLog close={closeRequestLog} />
      <Changelog />
    </>
  )
}
