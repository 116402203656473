import type { ReactElement } from 'react'

import { Stack } from '@mui/material'

import { Cross } from '@/icons'
import { FlexBox, Text, IconButton, H3 } from '@/local/components'

type Props = {
  title: string
  subTitle?: string
  Icon?: ReactElement
  closeButtonClassName?: string
  close?: () => void
  hideCloseButton?: boolean
  minHeight?: number
}

export function ModalHeader(props: Props) {
  const {
    Icon,
    title,
    subTitle,
    close,
    closeButtonClassName = '',
    hideCloseButton = false,
    minHeight = 0,
  } = props

  return (
    <Stack
      width="100%"
      sx={{
        color: 'text.primary',
        backgroundColor: 'grey.100',
        minHeight,
      }}
      paddingX={4}
      paddingY={2}
      alignItems="center"
      direction="row"
    >
      {!!Icon && (
        <FlexBox column>
          {Icon}
          {/* <Icon size={18} preset={icon} /> */}
        </FlexBox>
      )}

      <FlexBox column grow={1} gutter={18}>
        <H3>{title}</H3>

        {!!subTitle && (
          <Text size="$s" style={{ marginTop: 2 }}>
            {subTitle}
          </Text>
        )}
      </FlexBox>

      {!hideCloseButton && (
        <FlexBox column>
          <IconButton onClick={close} Icon={<Cross size={10} />} className={closeButtonClassName} />
        </FlexBox>
      )}
    </Stack>
  )
}
