import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()

  const { close } = useController()
  const { onSubmit } = useControllerActions()

  return (
    <ModalFooter>
      <ModalFooterButton
        onClick={onSubmit}
        testid="modal__button-add"
        variant="contained"
        color="primary"
      >
        {texts.footer.add}
      </ModalFooterButton>

      <ModalFooterButton onClick={close}>{texts.footer.cancel}</ModalFooterButton>
    </ModalFooter>
  )
}
