import type { ReactNode } from 'react'

import { Stack } from '@mui/material'

interface Props {
  children?: ReactNode
}

export function ModalFooter(props: Props) {
  return (
    <Stack
      width="100%"
      spacing={1}
      marginTop={3}
      direction="row"
      paddingLeft={4}
      paddingRight={4}
      paddingBottom={2}
      justifyContent="flex-end"
    >
      {props.children}
    </Stack>
  )
}
