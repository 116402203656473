import { Delete } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const { close } = useController()
  const texts = useTexts()

  return (
    <ModalHeader
      close={close}
      title={texts.headerTitle}
      Icon={<Delete color="$pureBlack" size={16} />}
    />
  )
}
