import { ModalHeader } from '@/components/Modal'
import { getSourceDetails } from '../../../../../utils/getSourceDetails'
import { useTexts } from '../../../useTexts'

interface Props {
  close?: () => void
  source: uui.domain.server.gps.telematics.Source
}
export function VerizonConnect(props: Props) {
  const { close, source } = props

  const texts = useTexts()

  const { logoSmallUrl } = getSourceDetails(source.label)

  return (
    <ModalHeader
      close={close}
      title={texts.verizonConnect.headerTitle}
      Icon={<img src={logoSmallUrl} />}
    />
  )
}
