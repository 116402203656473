import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const { invalid, status, close } = useController()
  const { handleOnConfirm, handleOnChangeFile } = useControllerActions()

  return (
    <ModalFooter>
      <ModalFooterButton
        disabled={invalid || status !== 'ready'}
        onClick={handleOnConfirm}
        variant="contained"
      >
        {texts.commandButtonTitle}
      </ModalFooterButton>
      <ModalFooterButton onClick={handleOnChangeFile}>
        {texts.changeFileButtonTitle}
      </ModalFooterButton>
      <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
    </ModalFooter>
  )
}
