import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'

import { useDeleteDrivers } from '../hooks/useDeleteDrivers'
import { useTexts } from '../hooks/useTexts'

export function RegularFooter() {
  const {
    status,
    close,
    data: { driverIds },
  } = useController()

  const texts = useTexts()
  const deleteDrivers = useDeleteDrivers(driverIds)

  const submitting = status === 'submitting'

  return (
    <ModalFooter>
      <ModalFooterButton
        color="error"
        variant="contained"
        onClick={deleteDrivers}
        loading={submitting}
        disabled={submitting}
        maxWidth="50%"
        testid="modal-delete-drivers-delete"
      >
        {texts.delete(driverIds.length)}
      </ModalFooterButton>

      <ModalFooterButton
        variant="text"
        onClick={close}
        disabled={submitting}
        testid="modal-delete-drivers-cancel"
      >
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
