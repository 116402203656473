import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getMap } from '../../atoms/map/utils/getMap'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'

import { createTrafficAreaFeature } from './utils/createTrafficAreaFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Initialize the Features for all the existent Traffic areas
 */
export async function initializeTrafficAreaFeatures(
  trafficProfiles: uui.domain.ui.map.markers.MapMarkers['trafficProfile'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  selection: Set<string>,
) {
  const map = getMap()
  const layer = findLayer(map, 'trafficProfile')

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const trafficProfile of Object.values(trafficProfiles)) {
    for (const marker of Object.values(trafficProfile)) {
      await nonblockingRender.next()

      const selected = selection.has(marker.id)
      features.push(createTrafficAreaFeature(marker, mapStyles, selected))
    }
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // keep track of selected items in the current layer
  setLayerMetadata(layer, 'selection', new Set(selection))
}
