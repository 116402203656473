import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

type Props = {
  onSubmit: () => void
}

export function Footer(props: Props) {
  const { onSubmit } = props

  const texts = useTexts()

  const { close, status } = useController()

  return (
    <ModalFooter>
      <ModalFooterButton
        disabled={status !== 'ready'}
        loading={status !== 'ready'}
        onClick={onSubmit}
        testid="modal__button-create"
        variant="contained"
      >
        {texts.addConnectionButtonTitle}
      </ModalFooterButton>

      <ModalFooterButton disabled={status !== 'ready'} onClick={close}>
        {texts.cancelButtonTitle}
      </ModalFooterButton>
    </ModalFooter>
  )
}
