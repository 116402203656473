import type { FormFields, FormErrors } from '../../../formFields'

import { BulkSelect } from '../../primitives/BulkSelect'
import { useTexts } from './useTexts'
import { useOptions } from './useOptions'

export function RequiredVehicle() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <BulkSelect<'forceVehicleId', string, FormFields, FormErrors>
      name="forceVehicleId"
      mixedLabel={texts.mixed}
      options={options}
      label={texts.requiredVehicle}
      testId="forceVehicleId"
    />
  )
}
