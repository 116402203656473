import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { HasPrivileges } from '@/components/HasPrivileges'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Email } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function ExportToEmailButton(props: Props) {
  const { onClick, disabled } = props
  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <HasPrivileges exportToEmail>
      <Tooltip title={texts.disabledMailToDriversTooltip} disabled={!disabled} placement="top">
        <NavigoIconButton
          onClick={onClick}
          disabled={disabled}
          text={texts.emailExport}
          Icon={<Email size={17} color={iconColor} />}
          testid="navigo-routes-overview-actions-export-to-email"
        />
      </Tooltip>
    </HasPrivileges>
  )
}
