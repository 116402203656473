import { ModalHeader } from '@/components/Modal'
import { SendCommunication } from '@/icons'

import { useController } from '../../hooks/useController'
import { useTexts } from './hooks/useTexts'

export function Header() {
  const { close } = useController()

  const texts = useTexts()

  return (
    <ModalHeader
      close={close}
      title={texts.title}
      subTitle={texts.subtitle}
      Icon={<SendCommunication size={15} />}
    />
  )
}
