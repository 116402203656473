import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { type AppDispatch } from '@/store'

import { useTexts } from '../useTexts'

import { createVerizonConnectFormValidation } from './createVerizonConnectFormValidation'
import { createLinxupFormValidation } from './createLinxupFormValidation'
import { createAzugaFormValidation } from './createAzugaFormValidation'

export function createFormValidation(
  source: uui.domain.server.gps.telematics.Source,
  tenantSource: uui.domain.server.gps.telematics.TenantSource,
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  switch (source.label) {
    case 'Azuga':
      return createAzugaFormValidation(tenantSource.id, dispatch, texts)

    case 'Linxup':
      return createLinxupFormValidation(tenantSource.id, dispatch, texts)

    case 'Verizon Connect':
      return createVerizonConnectFormValidation(tenantSource.id, dispatch, texts)
  }
}
