import type { Option } from '../../primitives/BulkSelect'
import type { Priority } from '../../../formFields'

import { useMemo } from 'react'

import { useTexts } from './useTexts'

export function useOptions() {
  const texts = useTexts()

  return useMemo(() => {
    return [
      {
        label: texts.lowest,
        value: '-20',
      },
      {
        label: texts.lower,
        value: '-10',
      },
      {
        label: texts.normal,
        value: '0',
      },
      {
        label: texts.higher,
        value: '10',
      },
      {
        label: texts.highest,
        value: '20',
      },
    ] as Option<Priority>[]
  }, [texts])
}
