import { Letter } from '@/icons'
import { useCreateDate, useFormatDateTime } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../hooks/useTexts'

export function Header() {
  const texts = useTexts()
  const { close, data } = useController()

  const date = useCreateDate(data.sentTs)
  const timeStamp = useFormatDateTime(date)

  return (
    <ModalHeader
      closeButtonClassName="pendo-orderform_order-notification-tab-view-settings-close-modal-x"
      close={close}
      title={texts.headerTitle(data.kind === 'EMAIL')}
      Icon={<Letter size={16} />}
      subTitle={texts.sentOn(timeStamp, data.recipient)}
    />
  )
}
