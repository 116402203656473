import { Logout } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const { close } = useController()
  const { title, subtitle } = useTexts()

  return (
    <ModalHeader
      close={close}
      title={title}
      subTitle={subtitle}
      Icon={<Logout size={16} color="$outrageousRed" />}
    />
  )
}
