import type { IconContainerProps } from './typings'

import { theme } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

export function Paired(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          style={{ fill: props.color ? theme.colors[props.color] : 'currentColor' }}
          d="M2.4 3.1c-.765 0-1.4.635-1.4 1.4v4.9c0 .765.635 1.4 1.4 1.4h2.1v2.1a1.4 1.4 0 001.4 1.4h4.9a1.4 1.4 0 001.4-1.4V8a1.4 1.4 0 00-1.4-1.4H8.7V4.5c0-.765-.635-1.4-1.4-1.4H2.4zm0 1.4h4.9v4.9H2.4V4.5z"
        />
      </svg>
    </IconContainer>
  )
}
