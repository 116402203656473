import { Scrollbar } from '@workwave-tidal/tidal/components'

import { selectMaxSlotsForDispatch } from '@/features/domain/routeplan'
import { FlexBox, ConfirmPanel } from '@/local/components'
import { resetRoutingSidebar } from '@/atoms'
import { PaperPlane } from '@/icons'

import { PanelHeader } from './components/PanelHeader'
import { DayCard } from './components/DayCard'

import { useDispatchDays } from './hooks/useDispatchDays'
import { useTexts } from './hooks/useTexts'
import { useBanner } from './hooks/useBanner'

export function DispatchPanel() {
  const maxSlotsToDispatch = selectMaxSlotsForDispatch()
  const { days, dispatchedDaysCount } = useDispatchDays()
  const [isBannerVisible, forceCloseBanner] = useBanner(dispatchedDaysCount, maxSlotsToDispatch)

  const texts = useTexts()

  const limitReached = dispatchedDaysCount >= maxSlotsToDispatch
  return (
    <FlexBox column h="100%" tint="$pureWhite">
      <PanelHeader
        title={texts.headerTitle}
        subTitle={texts.subtitle(maxSlotsToDispatch, dispatchedDaysCount, limitReached)}
        Icon={<PaperPlane size={18} />}
        onClose={resetRoutingSidebar}
        closeButtonClassName="pendo-dispatch-panel__btn-close"
      />
      {isBannerVisible && (
        <ConfirmPanel
          title={texts.bannerTitle(maxSlotsToDispatch)}
          description={texts.bannerDescription}
          confirmText={texts.bannerConfirmText}
          onConfirm={forceCloseBanner}
        />
      )}
      <Scrollbar hideOverflow="x">
        {days.map(day => (
          <DayCard key={day.id} day={day} />
        ))}
      </Scrollbar>
    </FlexBox>
  )
}
