import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function CopyReasons(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M1.333 2C.597 2 0 2.597 0 3.333V8a.667.667 0 001.333 0V3.833a.5.5 0 01.5-.5h10.834a.667.667 0 000-1.333H1.333zM4 4.667c-.735 0-1.333.598-1.333 1.333v4c0 .735.598 1.333 1.333 1.333h.667a4.667 4.667 0 119.333 0h.667c.735 0 1.333-.598 1.333-1.333V6c0-.735-.598-1.333-1.333-1.333H4zM9.333 8a3.334 3.334 0 10.001 6.667 3.334 3.334 0 000-6.667zm0 1.333c.368 0 .667.299.667.667v.667h.667a.667.667 0 010 1.333H10v.667a.667.667 0 01-1.333 0V12H8a.667.667 0 010-1.333h.667V10c0-.368.298-.667.666-.667z" />
      </svg>
    </IconContainer>
  )
}
