import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.action.delete',
        values: { count },
      }),
    changePassword: translate({ id: 'form.drivers.view.actions.changePassword' }),
    labels: {
      email: translate({ id: 'form.drivers.view.labels.email' }),
      name: translate({ id: 'form.drivers.view.labels.name' }),
      driverTracked: translate({ id: 'form.drivers.view.labels.driverTracked' }),
      tracked: (tracked: boolean) =>
        translate({
          id: tracked ? 'form.drivers.view.labels.tracked' : 'form.drivers.view.labels.notTracked',
        }),
    },
  }))

  return api
}
