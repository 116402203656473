import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PasswordLock(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M13 0C10.3203 0 8.19531 0.832031 6.84375 2.34375C5.49219 3.85547 5 5.83984 5 7.90625V9H8V7.90625C8 6.3125 8.35938 5.12891 9.0625 4.34375C9.76562 3.55859 10.8984 3 13 3C15.1055 3 16.2383 3.53516 16.9375 4.3125C17.6367 5.08984 18 6.29688 18 7.90625V9H21V7.90625C21 5.82812 20.5117 3.82031 19.1562 2.3125C17.8008 0.804688 15.6758 0 13 0ZM5 10C3.34375 10 2 11.3438 2 13V23C2 24.6562 3.34375 26 5 26H21C22.6562 26 24 24.6562 24 23V13C24 11.3438 22.6562 10 21 10H5ZM7 16C8.10547 16 9 16.8945 9 18C9 19.1055 8.10547 20 7 20C5.89453 20 5 19.1055 5 18C5 16.8945 5.89453 16 7 16ZM13 16C14.1055 16 15 16.8945 15 18C15 19.1055 14.1055 20 13 20C11.8945 20 11 19.1055 11 18C11 16.8945 11.8945 16 13 16ZM19 16C20.1055 16 21 16.8945 21 18C21 19.1055 20.1055 20 19 20C17.8945 20 17 19.1055 17 18C17 16.8945 17.8945 16 19 16Z" />
      </svg>
    </IconContainer>
  )
}
