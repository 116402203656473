import type { FormFields, FormErrors } from '../../../formFields'

import { BulkSelect } from '../../primitives/BulkSelect'
import { useTexts } from './useTexts'
import { useOptions } from './useOptions'

export function Company() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <BulkSelect<'companyId', string, FormFields, FormErrors>
      name="companyId"
      mixedLabel={texts.mixed}
      options={options}
      label={texts.company}
      testId="companyId"
    />
  )
}
