import { Export } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    invalid,
    close,
    data: { selectedOrders },
  } = useController()

  return (
    <ModalHeader
      title={texts.modalTitle(selectedOrders.length)}
      Icon={<Export size={16} />}
      subTitle={invalid ? '' : texts.modalSubtitle}
      close={close}
      hideCloseButton
    />
  )
}
