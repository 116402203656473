import { ModalHeader } from '@/components/Modal'
import { Share } from '@/icons'

import { useController } from '../../../../hooks/useController'
import { useTexts } from '../../useTexts'

export function ChooseRecipientHeader() {
  const texts = useTexts()
  const { close, data } = useController()
  const subtitle = data.gpsOnly
    ? texts.vehicles(data.devices.length)
    : texts.drivers(data.drivers.length)

  return (
    <ModalHeader
      close={close}
      title={texts.shareLocation}
      Icon={<Share size={16} />}
      subTitle={subtitle}
    />
  )
}
