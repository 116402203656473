import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Api(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 30 30">
        <path d="M4 5C2.895 5 2 5.895 2 7V23C2 24.105 2.895 25 4 25H26C27.105 25 28 24.105 28 23V7C28 5.895 27.105 5 26 5H4ZM7.25195 11H9.74219L12.4316 19H10.2148L9.71484 17.2422H7.10352L6.58789 19H4.56836L7.25195 11ZM14 11H17.5039C19.2229 11 20.3867 12.1208 20.3867 13.8398C20.3867 15.5418 19.1618 16.6543 17.3828 16.6543H16.0332V19H14V11ZM23 11H25.0332V19H23V11ZM16.0352 12.5625V15.1191H16.9551C17.8201 15.1191 18.3301 14.6747 18.3301 13.8438C18.3291 13.0008 17.8258 12.5625 16.9668 12.5625H16.0352ZM8.36719 12.8027L7.49023 15.7793H9.33203L8.47266 12.8027H8.36719Z" />
      </svg>
    </IconContainer>
  )
}
