import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newDriver: translate({ id: 'form.drivers.edit.header.newDriver' }),
    save: translate({ id: 'form.drivers.edit.footer.save' }),
    create: translate({ id: 'form.drivers.edit.footer.create' }),
    cancel: translate({ id: 'form.drivers.edit.footer.cancel' }),

    labels: {
      name: translate({ id: 'form.drivers.edit.fields.name.label' }),
      email: translate({ id: 'form.drivers.edit.fields.email.label' }),
      password: translate({ id: 'form.drivers.edit.fields.password.label' }),
      trackDriver: translate({ id: 'form.drivers.edit.fields.trackDriver.label' }),
      gpsDeviceId: translate({ id: 'form.drivers.edit.fields.gpsDeviceId.label' }),
    },
    helpers: {
      trackDriver: translate({ id: 'form.drivers.edit.fields.trackDriver.helper' }),
    },
    tooltips: {
      noAvailableVirtualDevices: translate({
        id: 'form.drivers.edit.fields.trackDriver.tooltip.noAvailableVirtualDevices',
      }),
    },
  }))

  return api
}
