import { useMemo } from 'react'
import { differenceInCalendarDays } from 'date-fns/esm'
import { makeStyles } from '@mui/styles'
import { FileCopy } from '@mui/icons-material'

import { ModalHeader } from '@/components/Modal'
import { useFormatDateInterval } from '@/hooks'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export const useStyles = makeStyles({
  copyIcon: {
    fontSize: 15,
  },
})

export function Header() {
  const {
    close,
    data: {
      calendarRange: { start, end },
    },
  } = useController()
  const texts = useTexts()
  const classes = useStyles()

  const numberOfDaysToExport = useMemo(() => differenceInCalendarDays(end, start) + 1, [start, end])
  const interval = useFormatDateInterval(start, end, false, true)

  const title = `${texts.title(numberOfDaysToExport)}, ${interval}`

  return (
    <ModalHeader
      Icon={<FileCopy className={classes.copyIcon} />}
      subTitle={texts.subtitle}
      title={title}
      close={close}
    />
  )
}
