import { Plus } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      title={texts.modalTitle}
      Icon={<Plus size={16} />}
      subTitle={texts.modalSubTitle}
      close={close}
    />
  )
}
