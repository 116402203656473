import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'

import { FormColumn } from '@/components/layout'
import { ViewHeader } from '@/components/formUi'
import { ViewContent, ViewField, ViewFieldsLayout } from '@/formUi'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectGpsTrackingProvider } from '@/features/domain/user'

import { useDeleteDriverModal } from '../../../modals/DeleteDriver'
import { useChangePasswordModal } from '../../../modals/ChangePassword'

import { useViewHeaderProps } from './hooks/useViewHeaderProps'
import { useTexts } from './hooks/useTexts'

interface Props {
  driver: uui.domain.client.rm.ExtendedDriver
}

export function SingleView(props: Props) {
  const { driver } = props
  const {
    driver: {
      id: driverId,
      name,
      deployment: { email },
    },
    type,
  } = driver

  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const viewHeaderProps = useViewHeaderProps(driver.driver)
  const texts = useTexts()
  const { Modal: DeleteDriverModal } = useDeleteDriverModal()
  const { Modal: ChangePasswordModal } = useChangePasswordModal()

  const tracked = type === 'linked'

  const showTracked = trackingProvider !== 'telematics'

  return (
    <FormColumn width={360} testid="form__column-secondary">
      <ViewHeader {...viewHeaderProps} testid="driver-view-header" />
      <ViewContent>
        <ViewFieldsLayout paddingTop={2}>
          <ViewField label={texts.labels.name}>{name}</ViewField>
          <ViewField label={texts.labels.email}>{email}</ViewField>
          {showTracked && (
            <ViewField
              label={texts.labels.driverTracked}
              color={tracked ? undefined : 'textSecondary'}
            >
              <Stack sx={{ gap: 1 }} component="span" direction="row">
                {texts.labels.tracked(tracked)}
                {tracked && (
                  <Tooltip
                    placement="bottom"
                    title={driver.type === 'linked' ? driver.device.label : ''}
                  >
                    <InfoIcon />
                  </Tooltip>
                )}
              </Stack>
            </ViewField>
          )}
        </ViewFieldsLayout>
      </ViewContent>
      <DeleteDriverModal
        driverIds={[driverId]}
        validationResult={viewHeaderProps.deleteValidationResult}
      />
      <ChangePasswordModal driverId={driverId} />
    </FormColumn>
  )
}
