import { PartialStoreState } from '../typings'

// maximum days that can be dispatched (no adjacent restriction)
export const selectMaxSlotsForDispatch = () => 5

// a day can be dispatched if day <= today + dispatchHorizon
export const selectHorizonDispatch = () => 14

export const selectApprovedPlans = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.approvedPlans

export const selectSnapshotsByCalendarRange = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.snapshotsByCalendarRange

export const selectRoutePlanTrackingData = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.routePlanTrackingData.routesTrackingData
