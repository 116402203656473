import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Update(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M20 12C20 16.418 16.418 20 12 20C7.58203 20 4 16.418 4 12C4 7.58203 7.58203 4 12 4C13.1133 4 14.168 4.23828 15.1328 4.64844L12.6289 7.32422L20.1211 7.07422L19.4844 0L17.2734 2.35938C15.7109 1.5 13.9141 1 12 1C5.92578 1 1 5.92578 1 12C1 18.0742 5.92578 23 12 23C18.0742 23 23 18.0742 23 12H20Z" />
      </svg>
    </IconContainer>
  )
}
