import { useMemo } from 'react'
import { Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { intl } from '@/intl'

import { getSourceDetails } from '../../../utils/getSourceDetails'
import { useTexts } from '../../../hooks/useTexts'

import { DiscoverIntegrationCard } from './DiscoverIntegrationCard'

type Props = {
  availableIntegrations: uui.domain.server.gps.telematics.Source[]
  activeIntegrations: uui.domain.server.gps.telematics.TenantSource[]
  onIntegrationClick: () => void
  onSetSource: (source: uui.domain.server.gps.telematics.Source) => void
  onShowDialog: () => void
  userRole: 'admin' | 'courier' | 'planner' | 'viewer' | 'guest' | 'gpsonly'
}

export function DiscoverIntegrationsGrid(props: Props) {
  const {
    availableIntegrations,
    onIntegrationClick,
    activeIntegrations,
    onSetSource,
    onShowDialog,
    userRole,
  } = props

  const texts = useTexts()
  const theme = useTheme()

  const lowerThanLg = useMediaQuery(theme.breakpoints.down('lg'))

  const renderSeeAllButton =
    availableIntegrations.length > 3 || (lowerThanLg && availableIntegrations.length > 2)

  const activeConnectionsBySourceId = useMemo(
    () =>
      activeIntegrations.reduce<Record<string, number>>((acc, integration) => {
        if (acc[integration.sourceId] === undefined) {
          acc[integration.sourceId] = 0
        }
        acc[integration.sourceId]++
        return acc
      }, {} as Record<string, number>),
    [activeIntegrations],
  )

  return (
    <Stack padding={4} sx={{ backgroundColor: '#0081FF1A' }} borderRadius={2} spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="caption">{texts.discoverIntegrations}</Typography>
        {renderSeeAllButton && (
          <Button onClick={onShowDialog} variant="text">
            {texts.seeAllIntegrations}
          </Button>
        )}
      </Stack>

      <Grid container spacing={1} width="100%" height="100%" overflow="hidden" flexWrap="nowrap">
        {availableIntegrations.map(integration => {
          const { logoSmallUrl, descriptionId } = getSourceDetails(integration.label)
          const onClick =
            userRole === 'admin'
              ? () => {
                  onSetSource(integration)
                  onIntegrationClick()
                }
              : undefined

          return (
            <Grid item md={6} lg={4} key={integration.id} flexShrink={0}>
              <DiscoverIntegrationCard
                connections={activeConnectionsBySourceId[integration.id]}
                logo={logoSmallUrl}
                onClick={onClick}
                integration={integration}
                description={intl.translate({ id: descriptionId })}
              />
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}
