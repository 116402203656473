import { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import VectorLayer from 'ol/layer/Vector'
import VectorImageLayer from 'ol/layer/VectorImage'

import { useSelector } from 'react-redux'
import { selectMapStyles } from '@/features/domain/ui'
import { selectMapMarkers } from '@/features/domain/mapMarkers'

// ------------------------------------------------------------------------
// Private Map API accessed directly by the tester
// ------------------------------------------------------------------------

import { getMap } from '../../../../../../map/atoms/map/utils/getMap'
import { getLayerMetadata } from '../../../../../../map/layers/layerMetadata'
import { getOrderFeatureMetadata } from '../../../../../../map/layers/order/orderFeatureMetadata'
import { getDepotFeatureMetadata } from '../../../../../../map/layers/depot/depotFeatureMetadata'

// ------------------------------------------------------------------------

type MapOrderStats = {
  ordersCount: number
  featuresCount: number
  markersWithSingleOrder: number
  markersWithMultipleOrders: number
  ordersInStackedMarkers: number
  markersWithText: number

  off: number
  on: number
  maximized: number

  atDepot: number
}

type MapDepotStats = {
  featuresCount: number

  off: number
  on: number
  label: number
}

type MapStats = {
  order: MapOrderStats
  depot: MapDepotStats
}

function createEmptyMapStats(): MapStats {
  return {
    order: {
      ordersCount: 0,
      featuresCount: 0,
      markersWithSingleOrder: 0,
      markersWithMultipleOrders: 0,
      ordersInStackedMarkers: 0,
      markersWithText: 0,
      off: 0,
      on: 0,
      maximized: 0,
      atDepot: 0,
    },
    depot: {
      featuresCount: 0,
      off: 0,
      on: 0,
      label: 0,
    },
  }
}

function createMapStats(
  mapMarkers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
): MapStats {
  const map = getMap()
  const layers = map.getLayers().getArray()

  const stats = createEmptyMapStats()

  for (const layer of layers) {
    if (!(layer instanceof VectorLayer) && !(layer instanceof VectorImageLayer)) continue

    const uid = getLayerMetadata(layer, 'uid')

    if (uid === 'order') {
      const features = layer.getSource()?.getFeatures()

      const { mode: mainMode } = mapStyles.order

      for (const feature of features) {
        const ids = getOrderFeatureMetadata(feature, 'ids') ?? {}
        const mode = getOrderFeatureMetadata(feature, 'mode') ?? mainMode

        const ordersQty = Object.keys(ids).length

        if (ordersQty === 0) continue

        stats.order.featuresCount++
        stats.order.ordersCount += ordersQty
        stats.order[mode]++
        if (Array.isArray(feature.style_) && feature.style_.length > 1) {
          stats.order.markersWithText++
        }

        if (ordersQty > 1) {
          stats.order.markersWithMultipleOrders++
          stats.order.ordersInStackedMarkers += ordersQty
        } else {
          stats.order.markersWithSingleOrder++
        }
      }
    }

    if (uid === 'depot') {
      const features = layer.getSource()?.getFeatures()

      const { mode: mainMode } = mapStyles.depot

      for (const feature of features) {
        const depotId = feature.getId()
        const mode = getDepotFeatureMetadata(feature, 'mode') ?? mainMode

        if (!depotId) {
          console.warn(`Map Stats - Found a depot feature without ID`, feature)
          continue
        }

        const depotMarker = mapMarkers.depot[depotId]

        if (!depotMarker) {
          console.warn(`Map Stats - Impossible to find depot marker with ID: ${depotId}`, feature)
          continue
        }

        stats.depot.featuresCount++
        stats.order.ordersCount += depotMarker.ordersCount
        stats.order.atDepot += depotMarker.ordersCount
        stats.depot[mode]++
      }
    }
  }

  return stats
}

export const useStyles = makeStyles({
  root: {
    '& table': {
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      width: '100%',
      wordBreak: 'break-word',
    },
  },
  gridCell: {
    padding: '10px 5px',
    textAlign: 'center',
    border: '1px solid black',
    minWidth: '50px',
  },
  gridTitle: {
    border: '1px solid',
    borderBottom: '0',
    marginBottom: '0',
    padding: '10px 0',
    textAlign: 'center',
    width: '100%',
  },
})

export function MapStats() {
  const classes = useStyles()
  const mapStyles = useSelector(selectMapStyles)
  const mapMarkers = useSelector(selectMapMarkers)

  const handlePrintMapStats = () => setStats(createMapStats(mapMarkers, mapStyles))

  const [stats, setStats] = useState(createEmptyMapStats)

  useEffect(() => {
    handlePrintMapStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <h2>
        LocationPinTester - <button onClick={handlePrintMapStats}>Refresh</button>
      </h2>

      <h3 className={classes.gridTitle}>Orders</h3>

      <table>
        <thead>
          <tr>
            {Object.keys(stats.order).map(field => {
              let title = field

              switch (field) {
                case 'ordersCount':
                  title = 'total orders'
                  break
                case 'featuresCount':
                  title = 'order markers'
                  break
                case 'markersWithSingleOrder':
                  title = 'single markers'
                  break
                case 'markersWithMultipleOrders':
                  title = 'stacked markers'
                  break
                case 'ordersInStackedMarkers':
                  title = 'orders in stacked markers'
                  break
                case 'markersWithText':
                  title = 'markers with text'
                  break
              }

              return (
                <th key={`order_header_${field}`} className={classes.gridCell}>
                  {title}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.entries(stats.order).map(([field, value]) => {
              return (
                <td key={`order_${field}`} className={classes.gridCell}>
                  {value}
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>

      <h3 className={classes.gridTitle}>Depots</h3>

      <table>
        <thead>
          <tr>
            {Object.keys(stats.depot).map(field => {
              const title = field === 'featuresCount' ? 'depot markers' : field

              return (
                <th key={`depot_header_${field}`} className={classes.gridCell}>
                  {title}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.entries(stats.depot).map(([field, value]) => {
              return (
                <td key={`depot_${field}`} className={classes.gridCell}>
                  {value}
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
