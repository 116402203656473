import { HorizontalLayout } from '@/components/layout'

import { Separator } from '@/components/Separator'

import { useIsSimulation, useHasPrivileges } from '@/hooks'

import { useRoutesNavigator } from '../../../../hooks/useRoutesNavigator'
import { ExportToEmailButton } from './components/ExportToEmailButton'
import { ExportRoutesButton } from './components/ExportRoutesButton'
import { RouteDetailsButton } from './components/RouteDetailsButton'
import { VehicleJsonButton } from './components/VehicleJsonButton'
import { UnassignButton } from './components/UnassignButton'
import { DispatchButton } from './components/DispatchButton'
import { ReverseButton } from './components/ReverseButton'
import { UnlockButton } from './components/UnlockButton'
import { ExpandButton } from './components/ExpandButton'
import { RevokeButton } from './components/RevokeButton'
import { BuildButton } from './components/BuildButton'
import { EditButton } from './components/EditButton'
import { LockButton } from './components/LockButton'
import { SwapButton } from './components/SwapButton'

import { disabledByPermission, showByPermission } from './utils'
import { useRoutesOrderStepIds } from './hooks/useRoutesOrderStepIds'
import { useModalActions } from './hooks/useModalActions'
import { usePermissions } from './hooks/usePermissions'
import { useActions } from './hooks/useActions'
import { useStyles } from './hooks/useStyles'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
}

export function Actions(props: Props) {
  const { expanded, toggleExpanded } = props

  const { currentRouteIds } = useRoutesNavigator()
  const orderStepIds = useRoutesOrderStepIds(currentRouteIds)
  const permissions = usePermissions()

  const { modals, modalActions } = useModalActions(orderStepIds)
  const canExportInSimulation = useHasPrivileges({ export: true }, true)
  const isSimulation = useIsSimulation()
  const actions = useActions(modalActions.showBuildProgressModal)
  const classes = useStyles()

  const multiple = currentRouteIds.length > 1

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-overview-actions"
      data-testid="navigo-routes-overview-actions"
      alignItems="center"
      width="auto"
    >
      {expanded && (
        <HorizontalLayout width="auto" className={classes.actionsWrapper}>
          {showByPermission(permissions.vehicleJson) && (
            <>
              <VehicleJsonButton currentRouteIds={currentRouteIds} />
              <Separator />
            </>
          )}

          {showByPermission(permissions.routeDetails) && (
            <>
              <RouteDetailsButton
                onClick={actions.toggleRouteDetails}
                disabled={disabledByPermission(permissions.routeDetails)}
              />
              <Separator />
            </>
          )}

          {showByPermission(permissions.lock) && (
            <LockButton
              multiple={multiple}
              onClick={actions.lock}
              disabled={disabledByPermission(permissions.lock)}
            />
          )}

          {showByPermission(permissions.unlock) && (
            <UnlockButton
              multiple={multiple}
              onClick={actions.unlock}
              disabled={disabledByPermission(permissions.unlock)}
            />
          )}

          {showByPermission(permissions.swap) && (
            <SwapButton onClick={actions.swap} disabled={disabledByPermission(permissions.swap)} />
          )}

          <ReverseButton onClick={actions.invert} permission={permissions.reverse} />

          <Separator />

          {showByPermission(permissions.exportToEmail) && (
            <ExportToEmailButton
              onClick={actions.exportToDriver}
              disabled={disabledByPermission(permissions.exportToEmail)}
            />
          )}

          {showByPermission(permissions.dispatch) && (
            <DispatchButton onClick={actions.dispatch} permission={permissions.dispatch} />
          )}

          {showByPermission(permissions.revoke) && (
            <RevokeButton
              multiple={multiple}
              onClick={actions.revoke}
              permission={permissions.revoke}
            />
          )}

          {(showByPermission(permissions.exportToEmail) ||
            showByPermission(permissions.dispatch) ||
            showByPermission(permissions.revoke)) && <Separator />}

          <ExportRoutesButton
            multiple={multiple}
            isSimulation={isSimulation}
            canExportInSimulation={canExportInSimulation}
            onShowExportRoutesModal={modalActions.showExportRoutesModal}
            onExportManifest={actions.onExportManifest}
            onExportDispatchedManifest={actions.onExportDispatchedManifest}
            canExportDispatchedManifest={actions.canExportDispatchedManifest()}
            disabled={disabledByPermission(permissions.export)}
          />

          {showByPermission(permissions.unassign) && (
            <UnassignButton
              multiple={multiple}
              onClick={modalActions.showUnassignOrderModal}
              permission={permissions.unassign}
            />
          )}

          {showByPermission(permissions.build) && (
            <BuildButton onClick={actions.build} permission={permissions.build} />
          )}

          <Separator />

          <EditButton onClick={actions.edit} disabled={disabledByPermission(permissions.edit)} />
        </HorizontalLayout>
      )}

      <ExpandButton expanded={expanded} onClick={toggleExpanded} />

      {modals}
    </HorizontalLayout>
  )
}
