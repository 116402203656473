import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Ticket(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="m49.207 15.094-3.102-3.102c-.359-.36-.933-.39-1.332-.074C43.88 12.637 42.88 13 41.801 13c-2.594 0-4.7-2.11-4.7-4.7 0-1.077.364-2.077 1.079-2.976a.999.999 0 0 0-.07-1.332L35.007.895C33.953-.165 32.047-.16 30.996.89l-5.363 5.328 1.375 1.375a1 1 0 1 1-1.414 1.414l-1.38-1.38L.794 30.896a2.807 2.807 0 0 0-.816 2.003c0 .77.289 1.48.816 2.008l3.102 3.102c.359.36.93.394 1.332.074.949-.762 2.043-1.184 3.074-1.184 2.59 0 4.699 2.11 4.699 4.7a4.504 4.504 0 0 1-1.145 3.03c-.355.4-.34 1 .04 1.38l3.097 3.097a2.82 2.82 0 0 0 2.008.82 2.82 2.82 0 0 0 2.008-.82l23.344-23.343-1.36-1.356a1 1 0 1 1 1.414-1.414l1.36 1.356 5.297-5.297c.55-.442.894-1.098.945-1.817a2.761 2.761 0 0 0-.801-2.14ZM31.211 13.21a.993.993 0 0 1-.707.293.993.993 0 0 1-.707-.293l-1.402-1.402a1 1 0 1 1 1.414-1.414l1.402 1.402a1 1 0 0 1 0 1.414Zm4.21 4.21a1 1 0 0 1-1.414 0l-1.402-1.401a1 1 0 1 1 1.415-1.415l1.402 1.403a1 1 0 0 1 0 1.414Zm4.212 4.212a1 1 0 0 1-1.414 0l-1.403-1.403a1 1 0 1 1 1.414-1.414l1.403 1.403a1 1 0 0 1 0 1.414Z" />
      </svg>
    </IconContainer>
  )
}
