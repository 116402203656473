import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../hooks/useControllerActions'
import { useController } from '../../hooks/useController'

import { useTexts } from './useTexts'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { close } = useController()

  return (
    <ModalFooter>
      <ModalFooterButton
        variant="contained"
        onClick={actions.sendFeedback}
        testid="give-feedback-modal-send-feedback"
      >
        {texts.sendFeedback}
      </ModalFooterButton>

      <ModalFooterButton onClick={close} testid="give-feedback-modal-cancel">
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
