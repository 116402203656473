import { useMemo } from 'react'
import { Skeleton, Stack } from '@mui/material'
import { useSelector } from 'react-redux'

import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { selectUserAccountPreferences } from '@/features/domain/user'
import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { timeUtils } from '@/server-data'
import { Text } from '@/local/components'

import { useTexts } from '../../../useTexts'

import { useComputeTravelledDistance } from '../hooks/useComputeTravelledDistance'

interface Props {
  routeId: string
  start: number
  end: number
  violations: uui.domain.rm.RouteViolations
}

function checkRouteVehicleViolationByType(
  violations: uui.domain.rm.RouteViolations,
  violationType: uui.domain.rm.RouteViolationsType,
) {
  const { vehicle } = violations

  if (vehicle) {
    return vehicle.some(
      (type: uui.domain.rm.RouteViolationsType): boolean => violationType === type,
    )
  }

  return false
}

export function WithTravelledDistance(props: Props) {
  const { start, end, routeId, violations } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const plannedStartTitle = `${texts.plannedStart}:`
  const plannedEndTitle = `${texts.plannedEnd}:`

  const plannedEndTitleColor = useMemo(() => {
    return checkRouteVehicleViolationByType(violations, 'TWE') ? '$outrageousRed' : '$pureBlack'
  }, [violations])

  const travelledDistanceResult = useComputeTravelledDistance(routeId)

  const startTime = timeUtils.formatSecondsFromMidnight(start, accountPreferences.timeFormat)
  const endTime = timeUtils.formatSecondsFromMidnight(end, accountPreferences.timeFormat)

  return (
    <VerticalLayout
      data-trackid="navigo-routes-overview-details-single-routeStats-timeBlock"
      data-testid="navigo-routes-overview-details-single-routeStats-timeBlock"
      justifyContent="space-between"
      marginLeft={24}
      width="auto"
    >
      <HorizontalLayout height="auto">
        <Stack direction="row" alignItems="flex-start" marginRight={3}>
          <Text size="$p3">{texts.travelledDistance}:&nbsp;</Text>
          {travelledDistanceResult.status === 'loading' ? (
            <Skeleton variant="text" width={50} height={14} />
          ) : (
            <Text
              testid="navigo-routes-overview-details-single-routeStats-timeBlock-startTime"
              size="$p3"
              weight="$semiBold"
            >
              <ConvertDistance meters={travelledDistanceResult.value} />
            </Text>
          )}
        </Stack>

        <Text size="$p3">{plannedEndTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-endTime"
          color={plannedEndTitleColor}
          weight="$semiBold"
          size="$p3"
        >
          {endTime}
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto">
        <Text size="$p3">{plannedStartTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-startTime"
          size="$p3"
          weight="$semiBold"
        >
          {startTime}
        </Text>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
