import { ModalHeader } from '@/components/Modal'
import { Attention } from '@/icons'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return <ModalHeader title={texts.title} close={close} Icon={<Attention size={22} />} />
}
