import type OlMap from 'ol/Map'
import type { ZoomMapControl } from '../../controls/ZoomMapControl'

import { proxy, ref } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { getInteraction, getInteractionMetadata } from '../../interactions/interactionMetadata'
import { registerMapInteractions } from '../../interactions/registerMapInteractions'

import {
  registerMapControls,
  connectMapControlsToLanguage,
} from '../../controls/registerMapControls'

import { initializeMapLayers } from '../../layers/initializeMapLayers'
import { getTexts } from '../../controls/registerMapControls/texts'

import { setLiveTrafficLayerVisibility } from './utils/setLiveTrafficLayerVisibility'
import { createMap } from './utils/createMap'

export type HereMapAuthenticationToken =
  | { type: 'apiKey'; value: string }
  | { type: 'oAuth'; value: string }

export type MapTile = 'normal' | 'hybrid'
export type MapSelectionMode = 'normal' | 'lasso' | 'lassoAdditive' | 'lassoSubtractive'

export type MapState = {
  // --------------------------
  // map instance

  map: OlMap
  status: 'mounted' | 'unmounted'

  // --------------------------
  // HERE Map authentication

  authenticationToken?: HereMapAuthenticationToken

  // --------------------------
  // Map configuration

  tile: MapTile
  locked: boolean
  showTraffic: boolean
  selectionMode: MapSelectionMode
}

// ------------------------------------
// Restore from Local Storage
// ------------------------------------

const localStorageId = 'routemanager/v1/map/config'

export const initialLockedValue = false
export const initialShowTrafficValue = false
export const initialSelectionModeValue = 'normal'

export const { tile: initialTileValue = 'normal', showTraffic = initialShowTrafficValue } =
  JSON.parse(localStorage.getItem(localStorageId) ?? '{}')

// ------------------------------------
// Map atom
// ------------------------------------

export const mapAtom = proxy<MapState>({
  status: 'unmounted',
  map: ref(createMap(undefined, { lat: 0, lng: 0 })) as OlMap,

  tile: initialTileValue,
  locked: initialLockedValue,
  showTraffic: showTraffic,
  selectionMode: initialSelectionModeValue,
})

// ------------------------------------
// Map Static initialization
// ------------------------------------

// initialize Map Controls
registerMapControls(mapAtom.map, mapAtom.tile)

// keep Map Controls synched with language and user preferences
connectMapControlsToLanguage(mapAtom.map)

// ------------------------------------
// Map Layers Static initialization
// ------------------------------------

initializeMapLayers(mapAtom.map)

// ------------------------------------
// User interactions
// ATTENTION: Must be done after layers initialization
// ------------------------------------

registerMapInteractions(mapAtom.map)

// ------------------------------------
// Write to Local Storage
// ------------------------------------

subscribeKey(mapAtom, 'tile', (tile: MapTile) => {
  localStorage.setItem(localStorageId, JSON.stringify({ tile }))
})

// ------------------------------------
// Update Map when the map atom change
// ------------------------------------

subscribeKey(mapAtom, 'locked', (locked: boolean) => {
  const { map } = mapAtom
  const texts = getTexts()

  map.getInteractions().forEach(int => {
    // ignore custom RM controlled interactions
    if (!getInteractionMetadata(int, 'rmInteraction')) {
      int.setActive(!locked)
    }
  })

  // Lock/unlock zoom controls
  const controls = map.getControls()

  const ctrl = controls.get('rm-zoom') as ZoomMapControl

  if (locked) {
    ctrl.changeTexts({
      zoomIn: { tooltip: texts.zoomInDisabledToolTip },
      zoomOut: { tooltip: texts.zoomOutDisabledToolTip },
    })
    ctrl.disable()
  } else {
    ctrl.resetTexts()
    ctrl.enable()
  }
})

subscribeKey(mapAtom, 'selectionMode', (selectionMode: MapSelectionMode) => {
  const lasso = getInteraction('rm-lasso-interaction')

  if (lasso) {
    switch (selectionMode) {
      case 'lasso':
      case 'lassoAdditive':
      case 'lassoSubtractive':
        // activate the interaction
        lasso.setActive(true)
        break

      case 'normal':
        // activate the interaction
        lasso.setActive(false)
        break
    }
  }
})

subscribeKey(mapAtom, 'showTraffic', (showTraffic: boolean) => {
  localStorage.setItem(localStorageId, JSON.stringify({ tile: mapAtom.tile, showTraffic }))
  setLiveTrafficLayerVisibility(showTraffic)
})
