import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function SuccessFooter() {
  const {
    close,
    data: { notification },
  } = useController()

  const texts = useTexts()

  return (
    <ModalFooter>
      <ModalFooterButton
        className={`pendo-${notification}-test-modal-ok`}
        testid={`${notification}-test-modal-ok`}
        variant="contained"
        onClick={close}
        maxWidth="50%"
      >
        {texts.ok}
      </ModalFooterButton>
      <ModalFooterButton
        className={`pendo-${notification}-test-modal-close-after-success`}
        testid={`${notification}-test-modal-close-after-success`}
        onClick={close}
      >
        {texts.close}
      </ModalFooterButton>
    </ModalFooter>
  )
}
