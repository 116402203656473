import { Duplicate } from '@/icons'
import { useFormatSeconds } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

function BulkHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrderSteps },
  } = useController()

  const title = texts.titleBulk
  const subtitle = texts.subtitleBulk(selectedOrderSteps.ids.length)

  return (
    <ModalHeader close={close} title={title} Icon={<Duplicate size={16} />} subTitle={subtitle} />
  )
}

function SingleHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrderSteps },
  } = useController()

  const {
    orderStep,
    orderStepType,
    order: { name },
  } = selectedOrderSteps.orderSteps[selectedOrderSteps.ids[0]]

  const title = texts.title(orderStepType)
  const serviceTime = useFormatSeconds(orderStep.serviceTimeSec)
  const subtitle = texts.subtitle({ name, serviceTime })

  return (
    <ModalHeader close={close} title={title} Icon={<Duplicate size={16} />} subTitle={subtitle} />
  )
}

export function InvalidHeader() {
  const texts = useTexts()
  const { close } = useController()

  return <ModalHeader title={texts.emptyOrdersTitle} Icon={<Duplicate size={16} />} close={close} />
}

export function Header() {
  const {
    invalid,
    data: { mode },
  } = useController()

  if (invalid) return <InvalidHeader />

  return mode === 'bulk' ? <BulkHeader /> : <SingleHeader />
}
