import { ModalHeader } from '@/components/Modal'
import { getSourceDetails } from '../../../../../utils/getSourceDetails'
import { useTexts } from '../../../useTexts'

interface Props {
  close?: () => void
  source: uui.domain.server.gps.telematics.Source
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}
export function Azuga(props: Props) {
  const { close, source, tenantSource } = props

  const texts = useTexts()

  const { logoSmallUrl } = getSourceDetails(source.label)

  return (
    <ModalHeader
      title={texts.headerTitle(tenantSource.label)}
      close={close}
      Icon={<img src={logoSmallUrl} />}
    />
  )
}
