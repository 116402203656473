import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Phone(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M5.03906 3C3.90906 3 2.96906 3.94031 3.03906 5.07031C3.30406 9.33931 5.14342 13.1786 7.98242 16.0176C10.8214 18.8566 14.6607 20.6959 18.9297 20.9609C20.0597 21.0309 21 20.0909 21 18.9609V17.2305C21 16.2105 20.2405 15.3602 19.2305 15.2402L16.7109 14.9492C16.1009 14.8792 15.5003 15.0895 15.0703 15.5195L13.2207 17.3691C11.8017 16.6491 10.5143 15.7107 9.40234 14.5977C8.28934 13.4857 7.35086 12.1983 6.63086 10.7793L8.48046 8.92969C8.91046 8.49969 9.12078 7.89906 9.05078 7.28906L8.75976 4.76953C8.63976 3.75953 7.78953 3 6.76953 3H5.03906ZM13 3C12.45 3 12 3.45 12 4V11.5059C12 11.9449 12.5308 12.1655 12.8418 11.8555L14.6953 10H20C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H13Z" />
      </svg>
    </IconContainer>
  )
}
