import { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { ArrowDropDown } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'

import { ButtonToolbar, ButtonToolbarItem } from '@/local/components'
import { useTransactionState } from '@/atoms'
import { selectTerritories } from '@/features/domain/territory'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../useTexts'

import { isRangeWithArchivedDays } from './utils/isRangeWithArchivedDays'
import { useRmToolbarActions } from './hooks/useRmToolbarActions'
import { useIsExportDisabled } from './hooks/useIsExportDisabled'
import { isSingleArchivedDay } from './utils/isSingleArchivedDay'
import { useOpenButtonText } from './hooks/useOpenButtonText'

interface Props {
  today: string
  showExport: boolean
  submitting: boolean
  onClose: () => void
  selectionEnd?: Date
  selectionStart?: Date
  onConfirm: () => void
  selectToday: () => void
  setExportDates: (dates: string[]) => void
  daysWithOrders: Record<string, number>
  setMultiterritory: (multiTerritory: boolean) => void
  setHighlightToday: (highlightToday: boolean) => void
  selectedDaysCount: number
  firstNonArchivedDay?: string
  showExportRoutesFromCalendarModal: () => void
}

function computeOpenButtonTooltipTitle(
  isArchivedDay: boolean,
  rangeWithArchivedDays: boolean,
  rangeOfArchivedDays: boolean,
  transactionInProgress: boolean,
  texts: ReturnType<typeof useTexts>,
) {
  if (isArchivedDay && transactionInProgress) {
    return texts.cannotArchivedDayDuringTransactionTitle
  }

  if (rangeOfArchivedDays) {
    return texts.cannotOpenArchivedRange
  }

  if (rangeWithArchivedDays) {
    return texts.cannotOpenRangeOfArchivedAndNotArchived
  }

  return ''
}

export function RmToolbar(props: Props) {
  const {
    onClose,
    onConfirm,
    showExport,
    submitting,
    selectToday,
    selectionEnd,
    daysWithOrders,
    selectionStart,
    setExportDates,
    selectedDaysCount,
    setHighlightToday,
    setMultiterritory,
    firstNonArchivedDay,
    showExportRoutesFromCalendarModal,
  } = props

  const [exportMenuOpen, setExportMenuOpen] = useState(false)
  const territories = useSelector(selectTerritories)
  const anchorRef = useRef(null)

  const transactionInProgress = useTransactionState().mode === 'controlled'

  const openButtonText = useOpenButtonText(selectedDaysCount, selectionStart, selectionEnd)

  const isArchivedDay = isSingleArchivedDay(selectionStart, selectionEnd, firstNonArchivedDay)

  const { rangeWithArchivedDays, rangeOfArchivedDays } = isRangeWithArchivedDays(
    selectionStart,
    selectionEnd,
    firstNonArchivedDay,
  )

  const exportDisabled = useIsExportDisabled(
    rangeWithArchivedDays,
    selectedDaysCount,
    daysWithOrders,
    selectionStart,
    selectionEnd,
  )

  const { highLightToday, unhighLightToday, goToExport, goToMultiterritoryExport } =
    useRmToolbarActions(
      setHighlightToday,
      showExportRoutesFromCalendarModal,
      setExportDates,
      selectionStart,
      selectionEnd,
      onClose,
      setMultiterritory,
    )

  const texts = useTexts()

  const openExportMenu = useCallback(() => {
    setExportMenuOpen(true)
  }, [])

  const closeExportMenu = useCallback(() => {
    setExportMenuOpen(false)
  }, [])

  const exportTooltipTitle =
    exportDisabled === 'noOrders' ? texts.noOrdersToExportTitle : texts.moreThan31ArchivedDaysTitle

  const openButtonTooltipDisabled =
    (!isArchivedDay || !transactionInProgress) && !rangeWithArchivedDays

  const openButtonTooltipTitle = computeOpenButtonTooltipTitle(
    isArchivedDay,
    rangeWithArchivedDays,
    rangeOfArchivedDays,
    transactionInProgress,
    texts,
  )

  const openButtonTooltipSubtitle =
    isArchivedDay && transactionInProgress
      ? texts.cannotArchivedDayDuringTransactionSubtitle
      : undefined

  const exportTooltipSubTitle = exportDisabled === 'noOrders' ? texts.noOrdersToExportSubtitle : ''

  const openButtonDisabled =
    (isArchivedDay && transactionInProgress) ||
    (!selectionStart && !selectionEnd) ||
    rangeWithArchivedDays

  const gotMultipleTerritories = Object.keys(territories).length > 1

  return (
    <ButtonToolbar justifyContent="end">
      <ButtonToolbarItem gutter={10}>
        <Tooltip
          placement="top"
          disabled={openButtonTooltipDisabled}
          subtitle={openButtonTooltipSubtitle}
          title={openButtonTooltipTitle}
        >
          <LoadingButton
            data-trackid="territory-calendar__open-button"
            data-testid="territory-calendar__open-button"
            disabled={openButtonDisabled}
            loading={submitting}
            onClick={onConfirm}
            variant="contained"
            color="primary"
            type="submit"
            size="medium"
            sx={{ minWidth: 130 }}
          >
            {openButtonText.toUpperCase()}
          </LoadingButton>
        </Tooltip>
      </ButtonToolbarItem>

      {showExport && (
        <ButtonToolbarItem gutter={10}>
          <ButtonGroup variant="outlined">
            <Tooltip
              placement="top"
              disabled={exportDisabled === false}
              subtitle={exportTooltipSubTitle}
              title={exportTooltipTitle}
            >
              <Button
                data-trackid="territory-calendar__export-button"
                data-testid="territory-calendar__export-button"
                disabled={exportDisabled !== false}
                onClick={goToExport}
                type="button"
                size="medium"
              >
                {texts.export.toUpperCase()}
              </Button>
            </Tooltip>
            {gotMultipleTerritories && (
              <Button
                size="small"
                ref={anchorRef}
                data-trackid="territory-calendar__export-button-contextual-menu"
                data-testid="territory-calendar__export-button-contextual-menu"
                onClick={openExportMenu}
              >
                <ArrowDropDown />
              </Button>
            )}
          </ButtonGroup>
          <Popper
            placement="bottom-start"
            anchorEl={anchorRef.current}
            open={exportMenuOpen}
            sx={{ zIndex: 1300 }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeExportMenu}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={goToMultiterritoryExport}
                    data-testid="territory-calendar__export-multiple-territories-button"
                  >
                    {texts.exportMultipleTerritories}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </ButtonToolbarItem>
      )}

      <ButtonToolbarItem
        gutter={10}
        onClick={selectToday}
        onMouseEnter={highLightToday}
        onMouseLeave={unhighLightToday}
      >
        <Button
          data-trackid="territory-calendar__today-button"
          data-testid="territory-calendar__today-button"
          color="primary"
          variant="text"
          type="button"
          size="medium"
        >
          {texts.today.toUpperCase()}
        </Button>
      </ButtonToolbarItem>

      <ButtonToolbarItem gutter={10}>
        <Button
          data-trackid="territory-calendar__cancel-button"
          data-testid="territory-calendar__cancel-button"
          color="primary"
          variant="text"
          type="button"
          size="medium"
          onClick={onClose}
        >
          {texts.cancel.toUpperCase()}
        </Button>
      </ButtonToolbarItem>
    </ButtonToolbar>
  )
}
