import { Rename } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    data: { oldName },
    close,
  } = useController()

  return (
    <ModalHeader
      title={texts.modalTitle}
      Icon={<Rename size={16} />}
      subTitle={texts.modalSubTitle(oldName)}
      close={close}
    />
  )
}
