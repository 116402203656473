import { ModalHeader } from '@/components/Modal'
import { Info } from '@/icons'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return <ModalHeader close={close} title={texts.title} Icon={<Info size={10} />} />
}
