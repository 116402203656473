import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({
        id: 'form.drivers.view.bulk.header.title',
        values: { count },
      }),
    delete: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.action.delete',
        values: { count },
      }),
  }))

  return api
}
