import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { status, invalid, close } = useController()

  return (
    <ModalFooter>
      <ModalFooterButton
        onClick={actions.onConfirmTerritoryChange}
        disabled={invalid || status !== 'ready'}
        variant="contained"
      >
        {texts.commandButtonTitle}
      </ModalFooterButton>
      <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
    </ModalFooter>
  )
}
