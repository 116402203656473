import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Delete(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" fill="none" fillOpacity="0" />
          <path
            d="M6.75 2l-.625.625h-2.5a.625.625 0 000 1.25h8.75a.625.625 0 000-1.25h-2.5L9.25 2h-2.5zM3.625 5.125v8.125c0 .688.563 1.25 1.25 1.25h6.25c.688 0 1.25-.563 1.25-1.25V5.125h-8.75zm2.756 2.134c.16 0 .32.06.441.182L8 8.619 9.178 7.44a.623.623 0 01.881.881L8.881 9.5l1.179 1.178a.623.623 0 01-.881.881L8 10.381 6.822 11.56a.623.623 0 01-.881-.881L7.119 9.5 5.94 8.322a.623.623 0 01.44-1.063z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </IconContainer>
  )
}
