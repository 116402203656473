import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useDriversRoot } from './useDriversRoot'
import { DriversList } from './DriversList'
import { SingleView } from './Single/SingleView'
import { BulkView } from './Bulk/BulkView'
import { SingleEdit } from './Single/EditView'

export function DriversRoot() {
  const { editing, drivers, selectedDrivers, selection } = useDriversRoot()
  const bulk = selectedDrivers.length > 1
  const single = selectedDrivers.length === 1

  const driver = selectedDrivers[0]

  useResetCrudSelectionOnUnmount('drivers')

  return (
    <>
      <DriversList editing={editing} drivers={drivers} selection={selection} />
      {bulk && <BulkView drivers={selectedDrivers} />}
      {editing && !bulk && <SingleEdit driver={driver} drivers={drivers} />}
      {single && !editing && driver && <SingleView driver={driver} />}
    </>
  )
}
