import type { DropDownOption } from '@/components/Dropdown'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'
import { selectRmVehicles } from '@/features/domain/vehicle'
import { naturalSort } from '@/server-data'
import { User } from '@/icons'

import { NOT_DISPATCHED } from '../../../constants'
import { useTexts } from './useTexts'

type DropdownOptionsByVehicleId = Record<
  string,
  { vehicleId: string; options: DropDownOption[]; label: string }
>

export function useDropdownOptionsByVehicleId(assignments: Record<string, string | null>) {
  const driversMap = useSelector(selectDrivers)
  const vehiclesMap = useSelector(selectRmVehicles)
  const associationVehicleByDriverId = Object.entries(assignments).reduce<Record<string, string>>(
    (acc, [vehicleId, driverId]) => {
      if (driverId) {
        acc[driverId] = vehicleId
      }
      return acc
    },
    {},
  )
  const texts = useTexts()

  return useMemo(() => {
    const vehicles = Object.values(vehiclesMap).sort((v1, v2) =>
      naturalSort(v1.externalId, v2.externalId),
    )
    const drivers = Object.values(driversMap).sort((d1, d2) =>
      naturalSort(d1.driver.name, d2.driver.name),
    )

    // Let's add external driver to the array
    const driverIds = Object.keys(associationVehicleByDriverId)
    for (const driverId of driverIds) {
      // If is an external vehicle, skip it
      if (associationVehicleByDriverId[driverId].indexOf('-external') !== -1) continue

      // If isn't an external driver skip it
      if (driverId.indexOf('-external') === -1) continue

      drivers.push(getExternalDriver(driverId, texts.externalDriver))
    }

    return vehicles.reduce<DropdownOptionsByVehicleId>((acc, vehicle) => {
      const vehicleId = vehicle.id
      const options = computeOptionByVehicleId({
        vehicleId,
        associationVehicleByDriverId,
        assignments,
        drivers,
        vehiclesMap,
        texts,
      })
      acc[vehicleId] = {
        options,
        vehicleId,
        label: vehicle.externalId,
      }
      return acc
    }, {})
  }, [driversMap, texts, assignments, vehiclesMap, associationVehicleByDriverId])
}

interface Params {
  vehicleId: string
  drivers: uui.domain.client.rm.ExtendedDriver[]
  vehiclesMap: Record<string, uui.domain.client.rm.Vehicle>
  assignments: Record<string, string | null>
  associationVehicleByDriverId: Record<string, string>
  texts: ReturnType<typeof useTexts>
}

function getExternalDriver(id: string, name: string): uui.domain.client.rm.ExtendedDriver {
  return {
    id,
    type: 'free',
    driver: {
      id,
      name,
      password: '',
      deployment: {
        email: '',
        gpsDeviceId: null,
      },
    },
  }
}

function computeOptionByVehicleId(params: Params): DropDownOption[] {
  const { vehicleId, drivers, assignments, associationVehicleByDriverId, texts, vehiclesMap } =
    params

  const result: DropDownOption[] = []
  const driversAlreadyInUse: DropDownOption[] = []
  const unassignedDrivers: DropDownOption[] = []

  let vehicleIdInUse = false

  for (const driver of drivers) {
    const vehicleName =
      vehiclesMap[associationVehicleByDriverId[driver.id]]?.externalId ?? undefined

    const alreadyInUse = !!associationVehicleByDriverId[driver.id]

    const selected = assignments[vehicleId] === driver.id

    if (selected) {
      vehicleIdInUse = true
    }

    if (alreadyInUse) {
      driversAlreadyInUse.push({
        id: driver.id,
        label: driver.driver.name,
        kind: 'item',
        selected,
        disabled: driver.id.indexOf('-external') !== -1,
        accent: selected ? 'default' : 'warning',
        extraLabel: vehicleName,
        Icon: <User size={14} color={selected ? '$silver' : '$darkOrange'} />,
      })
    } else {
      unassignedDrivers.push({
        id: driver.id,
        label: driver.driver.name,
        kind: 'item',
        Icon: <User size={14} color="$silver" />,
      })
    }
  }

  const notDispatchedOption: DropDownOption = {
    id: NOT_DISPATCHED,
    label: texts.notAssigned,
    kind: 'item',
    selected: !vehicleIdInUse,
    accent: !vehicleIdInUse ? 'default' : 'error',
    Icon: <User size={14} color="$outrageousRed" />,
  }

  result.push(notDispatchedOption)

  if (unassignedDrivers.length > 0) {
    result.push({
      id: 'unassigned-drivers',
      label: texts.unassignedDrivers,
      kind: 'header',
    })
    result.push(...unassignedDrivers)
  }

  if (driversAlreadyInUse.length > 0) {
    result.push({
      id: 'drivers-already-in-use',
      label: texts.driversAlreadyInUse,
      kind: 'header',
      tooltip: {
        title: texts.driversAlreadyInUseTooltipTitle,
        description: texts.driversAlreadyInUseTooltipDescription,
      },
    })
    result.push(...driversAlreadyInUse)
  }

  return result
}
