import { WarningTriangle } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function InvalidHeader() {
  const texts = useTexts()
  const {
    close,
    data: { initialSelection },
  } = useController()

  return (
    <ModalHeader
      title={texts.invalidDataEmptyTitle(initialSelection.length)}
      Icon={<WarningTriangle color="$pureWhite" background="$nightRider" size={18} />}
      subTitle=""
      close={close}
      hideCloseButton
    />
  )
}
