import type { MouseEvent } from 'react'
import type { FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../../../formFields'

import { useCallback } from 'react'
import { useForm } from '@workwave-tidal/tidal/form-fairy'

import { isDeepEqual } from '@/server-data'
import { useResetEditingState } from '@/atoms'

import { useUpdateDriver } from '../../../hooks/useUpdateDriver'

export function useFormActions(setFirstSubmitDone: (value: boolean) => void, driverId?: string) {
  const onDiscard = useResetEditingState()
  const { api } = useForm<FormFields, FormErrors>()

  const creating = driverId === undefined

  const updateDriver = useUpdateDriver(creating)

  const onEnter = useCallback(async () => {
    try {
      const formValueUnchanged = isDeepEqual(api.getValues(), api.getInitialValues())

      if (api.getMeta().status === 'pristine' || formValueUnchanged) {
        onDiscard()
        return
      }

      api.setSubmitting(true)
      setFirstSubmitDone(true)

      await api.waitForValidation()

      const valid =
        api.getMeta().status === 'indeterminate'
          ? await api.validate()
          : api.getMeta().status === 'valid'

      if (!valid) {
        api.setSubmitting(false)
        return
      }

      await updateDriver(computeDriverValues(api.getValues(), driverId))
    } catch (error) {
      //
    } finally {
      api.setSubmitting(false)
    }
  }, [updateDriver, api, driverId, setFirstSubmitDone, onDiscard])

  const onSubmit = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      onEnter()
    },
    [onEnter],
  )

  return {
    onDiscard,
    onSubmit,
    onEnter,
  }
}

function computeDriverValues(
  formValues: FormValues<FormFields>,
  driverId?: string,
): uui.domain.client.rm.Driver {
  const creating = driverId === undefined

  return {
    id: driverId ?? '',
    name: formValues.name,
    password: creating ? formValues['new-password'] : '',
    deployment: {
      email: formValues.email,
      gpsDeviceId: formValues.trackDriver ? formValues.gpsDeviceId : null,
    },
  }
}
