import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { differenceInCalendarDays } from 'date-fns/esm'

import { useMainSelection, useExportRoutesPreferences } from '@/atoms'
import { useHasPrivileges } from '@/hooks'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectApprovedRoutes, selectRoutes } from '@/features/domain/route'
import { selectCalendarRangeInterval } from '@/features/domain/ui'

export function useData() {
  const { planType } = useSelector(selectUserConfiguration)
  const canExportInSimulation = useHasPrivileges({ export: true }, true)
  const [selectedRoutesIds] = useMainSelection('routes')
  const routes = useSelector(selectRoutes)
  const [exportRoutesPreferences] = useExportRoutesPreferences()
  const {
    customizeColumns,
    includeBarcodes,
    currentRoutes: storedCurrentRoutes,
    includePod,
    format,
  } = exportRoutesPreferences

  const { start, end } = useSelector(selectCalendarRangeInterval)

  const isSimulation = planType === 'simulation'

  const selectedRoutes = useMemo(() => {
    return Object.values(routes).filter(route => selectedRoutesIds.includes(route.id))
  }, [routes, selectedRoutesIds])

  const approvedRoutesIds = useSelector(selectApprovedRoutes).map(route => route.id)
  const approvedRoutesAmount = selectedRoutesIds.filter(id => approvedRoutesIds.includes(id)).length
  const currentRoutes = approvedRoutesAmount === 0 ? true : storedCurrentRoutes

  // if endDate and startDate are the same the difference is 0
  const canExportPod = differenceInCalendarDays(end, start) + 1 <= 7

  return {
    isSimulation,
    selectedRoutes,
    approvedRoutesAmount,
    canExportInSimulation,
    format,
    includeBarcodes,
    includePod: !canExportPod ? false : includePod,
    selectedRoutesIds,
    canExportPod,
    currentRoutes,
    customizeColumns,
  }
}
