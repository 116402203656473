import { useMemo } from 'react'

import { FormColumn } from '@/components/layout'
import { ViewHeader } from '@/components/formUi'

import { useDeleteDriverModal } from '../../../modals/DeleteDriver'

import { useViewHeaderProps } from './hooks/useViewHeaderProps'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

export function BulkView(props: Props) {
  const { drivers } = props
  const driverIds = useMemo(() => drivers.map(extDriver => extDriver.driver.id), [drivers])

  const viewHeaderProps = useViewHeaderProps(driverIds)

  const { Modal: DeleteDriverModal } = useDeleteDriverModal()

  return (
    <FormColumn width={360} testid="form__column-secondary">
      <ViewHeader {...viewHeaderProps} />

      <DeleteDriverModal
        driverIds={driverIds}
        validationResult={viewHeaderProps.deleteValidationResult}
      />
    </FormColumn>
  )
}
