import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { DropdownMenu, MenuTrigger, DropdownMenuItem } from '@/components/DropdownMenu'

import { selectTotalTelematicsDemoEnable } from '@/features/domain/account'
import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { HasPrivileges } from '@/components/HasPrivileges'
import { CollapseText } from '@/components/CollapseText'
import { BusinessUnit } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'

import { selectData } from '../../cursors/selectData'

import { useCanSeeTerritorySettings } from './hooks/useCanSeeTerritorySettings'
import { ChangeTerritoryMenuItem } from './components/ChangeTerritoryMenuItem'
import { useTerritoryModals } from './hooks/useTerritoryModals'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

interface Props {
  disabled?: boolean
}

export function RmDropdown(props: Props) {
  const { disabled } = props

  const [open, setOpen] = useState(false)

  const telematicsDemoEnabled = useSelector(selectTotalTelematicsDemoEnable)
  const { territoryName } = useSelector(selectData)
  const texts = useTexts()

  const { navigateToTerritorySettings } = useActions()
  const {
    showStartTelematicsDemo,
    showImportDemoTerritory,
    showImportNewTerritory,
    showDeleteTerritory,
    showRenameTerritory,
    showTimeShiftPlan,
    modals,
  } = useTerritoryModals()

  const canSeeTerritorySettings = useCanSeeTerritorySettings()

  const handleTimeShiftPlan = useCallback(() => {
    showTimeShiftPlan()
    setOpen(false)
  }, [showTimeShiftPlan])

  const handleDeleteTerritory = useCallback(() => {
    showDeleteTerritory()
    setOpen(false)
  }, [showDeleteTerritory])

  const handleRenameTerritory = useCallback(() => {
    showRenameTerritory()
    setOpen(false)
  }, [showRenameTerritory])

  const handleImportDemoTerritory = useCallback(() => {
    showImportDemoTerritory()
    setOpen(false)
  }, [showImportDemoTerritory])

  const handleImportNewTerritory = useCallback(() => {
    showImportNewTerritory()
    setOpen(false)
  }, [showImportNewTerritory])

  const handleNavigateToTerritorySettings = useCallback(() => {
    navigateToTerritorySettings()
    setOpen(false)
  }, [navigateToTerritorySettings])

  const handleStartTelematicsDemo = useCallback(() => {
    showStartTelematicsDemo()
    setOpen(false)
  }, [showStartTelematicsDemo])

  return (
    <>
      <DropdownMenu
        autoWidth
        open={open}
        setOpen={setOpen}
        disabled={props.disabled}
        triggerTestid="header__territory-button"
        trigger={
          <Tooltip placement="bottom" title={texts.tooltipRm(territoryName)} disabled={disabled}>
            <MenuTrigger
              menuOpen={open}
              disabled={disabled}
              className="pendo-bluebar__territory-menu"
              testid="rm-territory-dropdown"
              Icon={<BusinessUnit size={16} marginRight={10} />}
            >
              <CollapseText maxWidth={150}>{territoryName}</CollapseText>
            </MenuTrigger>
          </Tooltip>
        }
      >
        <div data-testid="territoryDropdown__container">
          <ChangeTerritoryMenuItem setOpen={setOpen} />

          <HasPrivileges demo dev>
            <ReadOnlyTooltip
              placement="left"
              render={preventEditing => (
                <DropdownMenuItem
                  onClick={handleRenameTerritory}
                  disabled={preventEditing}
                  className="pendo-bluebar__territory-rename-territory-button"
                  testid="rename-territory-button"
                >
                  {texts.renameTerritory}
                </DropdownMenuItem>
              )}
            />
          </HasPrivileges>

          <HasPrivileges demo dev>
            <TransactionInProgressTooltip
              placement="left"
              render={preventEditing => (
                <DropdownMenuItem
                  disabled={preventEditing}
                  onClick={handleImportDemoTerritory}
                  className="pendo-bluebar__territory-import-demo-territory-button"
                  testid="import-demo-territory-button"
                >
                  {texts.importDemoTerritory}
                </DropdownMenuItem>
              )}
            />
          </HasPrivileges>

          <HasPrivileges demo dev>
            <TransactionInProgressTooltip
              placement="left"
              render={preventEditing => (
                <DropdownMenuItem
                  disabled={preventEditing}
                  onClick={handleImportNewTerritory}
                  className="pendo-bluebar__territory-import-new-territory-button"
                  testid="import-new-territory-button"
                >
                  {texts.importNewTerritory}
                </DropdownMenuItem>
              )}
            />
          </HasPrivileges>

          <HasPrivileges demo dev>
            <ReadOnlyTooltip
              render={disableEdit => (
                <DropdownMenuItem
                  onClick={handleTimeShiftPlan}
                  disabled={disableEdit}
                  className="pendo-bluebar__territory-time-shift-plan-button"
                  testid="timeshift-plan"
                >
                  {texts.timeShiftPlan}
                </DropdownMenuItem>
              )}
            />
          </HasPrivileges>

          {telematicsDemoEnabled && (
            <HasPrivileges demo dev>
              <ReadOnlyTooltip
                render={disableEdit => (
                  <DropdownMenuItem
                    onClick={handleStartTelematicsDemo}
                    disabled={disableEdit}
                    className="pendo-bluebar__territory-start-telematics-demo-button"
                    testid="start-telematics-demo-button"
                  >
                    {texts.startTelematicsDemo}
                  </DropdownMenuItem>
                )}
              />
            </HasPrivileges>
          )}

          {canSeeTerritorySettings && (
            <DropdownMenuItem
              external
              className="pendo-bluebar__territory-territory-settings-button"
              testid="territory-settings-button"
              onClick={handleNavigateToTerritorySettings}
            >
              {texts.territorySettings}
            </DropdownMenuItem>
          )}

          <HasPrivileges demo dev>
            <TransactionInProgressTooltip
              placement="left"
              render={preventEditing => (
                <DropdownMenuItem
                  warn
                  disabled={preventEditing}
                  onClick={handleDeleteTerritory}
                  className="pendo-bluebar__territory-delete-territory-button"
                  testid="territory-delete-button"
                >
                  {texts.deleteTerritory}
                </DropdownMenuItem>
              )}
            />
          </HasPrivileges>
        </div>
      </DropdownMenu>

      {modals}
    </>
  )
}
