import { Delete } from '@/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const { close } = useController()

  const texts = useTexts()

  return <ModalHeader close={close} title={texts.title} Icon={<Delete size={16} />} />
}
