import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SmsPlan(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M6 2C4.895 2 4 2.895 4 4V20C4 21.105 4.895 22 6 22H16.4277C16.1437 21.337 16 20.641 16 20H12C11.448 20 11 19.552 11 19C11 18.448 11.448 18 12 18H16H16.4297C16.1657 17.446 16 16.786 16 16C15.448 16 15 15.552 15 15C15 14.448 15.448 14 16 14C16.09 14 16.1709 14.0288 16.2539 14.0508C16.5049 13.4098 16.999 12.5805 18 11.9785C18 10.8865 18.8865 10 19.9785 10H20V4C20 2.895 19.105 2 18 2H6ZM6.5 4H17.5C17.776 4 18 4.224 18 4.5V7.5C18 7.776 17.776 8 17.5 8H6.5C6.224 8 6 7.776 6 7.5V4.5C6 4.224 6.224 4 6.5 4ZM8 10C8.552 10 9 10.448 9 11C9 11.552 8.552 12 8 12C7.448 12 7 11.552 7 11C7 10.448 7.448 10 8 10ZM12 10C12.552 10 13 10.448 13 11C13 11.552 12.552 12 12 12C11.448 12 11 11.552 11 11C11 10.448 11.448 10 12 10ZM16 10C16.552 10 17 10.448 17 11C17 11.552 16.552 12 16 12C15.448 12 15 11.552 15 11C15 10.448 15.448 10 16 10ZM21 12C20.448 12 20 12.448 20 13V13.1992C19 13.4992 18 14.4 18 16C18 17.9 19.5996 18.5004 20.5996 18.9004C21.7996 19.4004 22 19.5 22 20C22 20.5 21.8 21 21 21C20.2 21 20 20.5 20 20H18C18 21 18.6 22.4008 20 22.8008V23C20 23.552 20.448 24 21 24C21.552 24 22 23.552 22 23V22.8008C23.4 22.4008 24 21 24 20C24 18.1 22.4004 17.4996 21.4004 17.0996C20.2004 16.5996 20 16.5 20 16C20 15.2 20.5 15 21 15C21.8 15 22 15.5 22 16H23H24C24 15 23.4 13.5992 22 13.1992V13C22 12.448 21.552 12 21 12ZM8 14C8.552 14 9 14.448 9 15C9 15.552 8.552 16 8 16C7.448 16 7 15.552 7 15C7 14.448 7.448 14 8 14ZM12 14C12.552 14 13 14.448 13 15C13 15.552 12.552 16 12 16C11.448 16 11 15.552 11 15C11 14.448 11.448 14 12 14ZM8 18C8.552 18 9 18.448 9 19C9 19.552 8.552 20 8 20C7.448 20 7 19.552 7 19C7 18.448 7.448 18 8 18Z" />
      </svg>
    </IconContainer>
  )
}
