import type { ReactElement } from 'react'
import type { PrivilegesMap } from '@/hooks'

import { useHasPrivileges } from '@/hooks'

import { ExperimentalBackground } from './components/ExperimentalBackground'

type ComponentProps = {
  exact?: boolean
  children: ReactElement
  experimental?: boolean // Enable a yellow background on the whole component
}
type Props = ComponentProps & PrivilegesMap

export function HasPrivileges(props: Props) {
  const { exact, children, experimental, ...allowedPrivileges } = props
  const gotPrivileges = useHasPrivileges(allowedPrivileges, exact ?? false)

  return gotPrivileges ? (
    experimental ? (
      <ExperimentalBackground>{children}</ExperimentalBackground>
    ) : (
      children
    )
  ) : null
}
