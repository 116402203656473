import { Delete } from '@/icons'
import { useFormatSeconds } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

function BulkHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrders },
  } = useController()

  return (
    <ModalHeader
      close={close}
      title={texts.titleBulk}
      Icon={<Delete size={16} />}
      subTitle={texts.subtitleBulk(selectedOrders.length)}
    />
  )
}

function SingleHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrders },
  } = useController()

  const {
    orderStep,
    orderStepType,
    order: { name },
  } = selectedOrders[0]

  return (
    <ModalHeader
      close={close}
      title={texts.title(orderStepType)}
      Icon={<Delete size={16} />}
      subTitle={texts.subtitle({ name, serviceTime: useFormatSeconds(orderStep.serviceTimeSec) })}
    />
  )
}

export function InvalidHeader() {
  const texts = useTexts()
  const { close } = useController()

  return <ModalHeader title={texts.emptyOrdersTitle} Icon={<Delete size={16} />} close={close} />
}

export function Header() {
  const {
    invalid,
    data: { mode },
  } = useController()

  if (invalid) return <InvalidHeader />

  return mode === 'bulk' ? <BulkHeader /> : <SingleHeader />
}
