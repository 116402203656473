import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Footer() {
  const { close } = useController()

  const { openPreview } = useControllerActions()
  const texts = useTexts()

  return (
    <ModalFooter>
      <ModalFooterButton
        className="pendo-tracking-page-preview-open"
        testid="tracking-page-preview-open"
        onClick={openPreview}
        variant="contained"
        maxWidth="50%"
      >
        {texts.open}
      </ModalFooterButton>

      <ModalFooterButton
        className="pendo-tracking-page-preview-cancel"
        testid="tracking-page-preview-cancel"
        onClick={close}
      >
        {texts.cancel}
      </ModalFooterButton>
    </ModalFooter>
  )
}
