import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const { close } = useController()
  const texts = useTexts()

  return <ModalHeader close={close} title={texts.title} />
}
